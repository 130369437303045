import { Link, } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';

import localLibrary from './../../lib/localLibrary';
import config from '../../config';
import helpers from '../../lib/helpers';

function BookmarkButton(props) {
	const [selected, setSelected] = useState(false);
	const [popupVisible, setPopupVisible] = useState(false);

	useEffect(() => {
	}, []);

	let toggleSaveToLibrary = () => {
		var libraryItem = {
			id: props.id,
			title: props.title,
			type: props.type,
			info: props.info
		};

		if (props.audioUrl) {
			libraryItem.audioUrl = props.audioUrl;
		}

		if (!localLibrary.find(libraryItem)) {
			localLibrary.add(libraryItem);

			setPopupVisible(true);

			setTimeout(() => setPopupVisible(false), 3000);
		}
		else {
			localLibrary.remove(libraryItem);
			setPopupVisible(false);
		}

		localLibrary.sendSignal();
	}

	let saved = localLibrary.find({
		id: props.id,
		type: props.type
	});

	return <span className={'tool-button bookmark-button'+(props.className ? ' '+props.className : '')+(saved ? ' selected' : '')}>
		<button alt={props.label || (saved ? 'Eyða úr handraðanum' : 'Leggja í handraðann')}
			title={props.label || (saved ? 'Eyða úr handraðanum' : 'Leggja í handraðann')}
			onClick={() => {
			   toggleSaveToLibrary();
		   }} />
		{
			<span className={'popup-info'+(popupVisible ? ' visible' : '')}>Hægt er að skoða handraðann <Link to={helpers.routerUrls['saveditems']}>hér</Link> eða í valmyndinni hér að ofan.</span>
		}
	</span>;
}

export default BookmarkButton;
