import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PlayButton from './ui/PlayButton';
import LoadingBar from './ui/LoadingBar';
import ListFilter from './filters/ListFilter';
import PaginationButtons from './PaginationButtons';
import NoResults from './ui/NoResults';
import ThingMap from './ThingMap';
import TabContainer from './ui/TabContainer';

import config from './../config';
import helpers from './../lib/helpers';

function ThingList(props) {
	const [data, setData] = useState(null);
	const [nextUrl, setNextUrl] = useState(null);
	const [previousUrl, setPreviousUrl] = useState(null);
	const [resultCount, setResultCount] = useState(0);
	const [loading, setLoading] = useState(true);

	const history = useHistory();

	useEffect(() => {
		if (!data) {
			fetchData();
		}
	});

	useEffect(() => {
		fetchData();
	}, [props.locationId, props.searchParams]);

	const fetchData = () => {
		setLoading(true);
		setData(null);

		let urlParams = [];

		if (props.locationId) {
			urlParams.push('location='+props.locationId)
		}

		if (props.searchParams) {
			let params = helpers.parseSearchParams(props.searchParams)

			if (params.nafn) {
				urlParams.push('name='+params.nafn)
			}

			if (params.tegund) {
				urlParams.push('brand='+params.tegund)
			}

			if (params.sida) {
				urlParams.push('limit='+config.pageSize);
				urlParams.push('offset='+(config.pageSize*(params.sida-1)))
			}
		}

		helpers.fetch(config.apiRoot+'/api/things/?'+urlParams.join('&'), (json) => {
			setData(json.results);
			setLoading(false);
			setNextUrl(json.next);
			setPreviousUrl(json.previous);
			setResultCount(json.count);

			if (props.onResponse) {
				props.onResponse(json.results);
			}
		});
	}

	let table = <>
		{
			data && data.length > 0 &&
			<>
				<table className="table">
					<thead>
						<tr>
							<th>Nafn</th>
							<th>Tegund</th>
						</tr>
					</thead>
					<tbody>
						{
							data.map((item, index) => <tr key={index}>
								<td>
									<Link to={helpers.routerUrls['orgel']+item.id}>
										{
											item.name
										}
									</Link>
								</td>
								<td>{item.brand}</td>
							</tr>)
						}
					</tbody>
				</table>
				{
					resultCount > config.pageSize &&
					<PaginationButtons resultCount={resultCount}
						previousUrl={previousUrl}
						nextUrl={nextUrl} />
				}
			</>
		}
	</>

	return (
		<div className={'position-relative '+(props.className || '')+(loading ? ' loading' : '')}>
			<LoadingBar />
			{
				props.enableFilter &&
				<ListFilter filters={[
					{
						label: 'Nafn',
						paramName: 'nafn',
						type: 'text'
					},
					{
						label: 'Tegund',
						paramName: 'tegund',
						type: 'text'
					}
				]}
				onFilter={(event) => {
					history.push(history.location.pathname+helpers.updateSearchParams(props.searchParams, event));
				}} />
			}
			{
				data && data.length > 0 &&
				<>
					{
						props.children
					}
					{
						props.viewTabs &&
						<TabContainer useRouter={'true'} preserveSearchParams={true} selectedTab={props.match.params.tab} baseUrl={helpers.routerUrls['orgel']}>

							<div tabname="listi" tabtitle={'Orgel'}>
								{
									table
								}
							</div>

							<ThingMap tabname="kort" tabtitle={'Staðsetningar orgela'} searchParams={props.searchParams} />

						</TabContainer>
					}
					{
						!props.viewTabs &&
						table
					}
				</>
			}
			{
				data && data.length == 0 && !loading &&
				<NoResults />
			}
		</div>
	)
}

export default ThingList;
