import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import _ from 'underscore';

import helpers from './../../lib/helpers';

function NoResults(props) {
	const history = useHistory();

	const pageRoot = helpers.getPageRoot(history.location.pathname);

	return (
		<div className="search-no-results">
			<div className="icon search" />
			{
				props.searchTerm &&
				<p className="lead">Ekkert fannst undir síðuhlutanum <i>{helpers.getPathWithTitles(history.location.pathname).toLowerCase()}</i>.<br/>Smelltu <Link to={helpers.routerUrls['leit']+props.searchTerm}>hér til að leita á allri síðunni</Link>.</p>
			}
			{
				!props.searchTerm &&
				<p className="lead">Ekkert fannst.</p>
			}
			{
				props.children
			}
		</div>
	)
}

export default NoResults;
