import React from 'react';

function LinkedItems(props) {
	return (
		<div className="card gray-card mt-3">
			<div className="card-body">
				<span className="mr-3">Tengingar:</span>
				{props.children}
				<div><small>Tenglar sem vísa á sama efni á öðrum tegndum vefjum.</small></div>
			</div>
		</div>
	)
}

export default LinkedItems;
