import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AutocompleteInput from './ui/AutocompleteInput';
import PlayButton from './ui/PlayButton';
import _ from 'underscore';

import config from './../config';
import helpers from './../lib/helpers';

class SearchBox extends Component {
	constructor(props) {
		super(props);

		this.inputKeyPressHandler = this.inputKeyPressHandler.bind(this);
		this.inputChangeHandler = this.inputChangeHandler.bind(this);
		this.executeSearch = this.executeSearch.bind(this);
		this.formatAutocompleteList = this.formatAutocompleteList.bind(this);
		this.searchBoxItemSelectHandler = this.searchBoxItemSelectHandler.bind(this);

		this.state = {
			searchBoxInput: ''
		};
	}

	inputKeyPressHandler(event) {
		if (event.key == 'Enter') {
			this.executeSearch();
		}
	}

	inputChangeHandler(event) {
		let stateObj = {};

		stateObj[event.target.name] = event.target.value;

		this.setState(stateObj);

		this.refs.searchInput.refs.inputField.focus();
	}

	componentDidMount() {
		if (this.props.searchString) {
			this.setState({
				searchBoxInput: this.props.searchString
			});
		}

		this.refs.searchInput.refs.inputField.focus();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.searchString != prevProps.searchString) {
			this.setState({
				searchBoxInput: this.props.searchString
			});
		}
	}

	searchBoxItemSelectHandler(item) {
		this.props.history.push(
			helpers.routerUrls[item.type]+item.id+(
				item.inner_hits && item.inner_hits.length > 0 ? '?trloc='+item.inner_hits.map((hit) => hit.id).join(';') : ''
			)
		);
	}

	executeSearch() {
		this.props.history.push('/leit/'+encodeURIComponent(this.state.searchBoxInput));
	}

	formatAutocompleteList(item) {
		let source = _.where(item.metadata, {type: 'source'});

		let birthDate = _.findWhere(item.metadata, {type: 'date_of_birth'});
		let deathDate = _.findWhere(item.metadata, {type: 'date_of_death'});

		return <div className="pt-2 pb-2">
			<div>
				{item.type == 'book' ? item.title || item.contents : item.title} <small className="text-uppercase text-primary float-right">{helpers.typesPlural[item.type]}</small>
				{
					item.type == 'audio' && _.findWhere(item.metadata, {type: 'audio_file'}) &&
					<div className="float-left mr-2" style={{marginTop: '-1px'}}>
						<PlayButton className="small"
							audioUrl={_.findWhere(item.metadata, {type: 'audio_file'}).item}
							itemId={item.id}
							itemTitle={item.title}
							itemInfo={item.contents} />
					</div>
				}
				<br/>
				{
					item.type == 'location' &&
					<React.Fragment>
						<small>
							{
								_.findWhere(item.metadata, {type: 'type'}) && <React.Fragment>{_.findWhere(item.metadata, {type: 'type'}).item} | </React.Fragment>
							}
							{
								_.findWhere(item.metadata, {type: 'parents'}).items.slice(1).join(', ')
							}
						</small><br/>
					</React.Fragment>
				}
				{
					item.contents && item.contents.length > 0 &&
					<React.Fragment><small dangerouslySetInnerHTML={{__html: helpers.trim(item.contents, 100)}} /><br/></React.Fragment>
				}
				{
					((birthDate && birthDate.item != '') || (deathDate && deathDate.item != '')) &&
					<React.Fragment><small>{(birthDate ? helpers.formatDate(birthDate.item) : '')+'–'+(deathDate ? helpers.formatDate(deathDate.item) : '')}</small><br/></React.Fragment>
				}
				{
					item.type == 'person' && _.findWhere(item.metadata, {type: 'status'}) && _.findWhere(item.metadata, {type: 'status'}).arr &&
					<React.Fragment>
						<small><strong>
							{
								helpers.metadataList(_.findWhere(item.metadata, {type: 'status'}).arr, null, null, 'name', null, true)
							}
						</strong></small><br/>
					</React.Fragment>
				}
				<small><strong>{helpers.metadataList(source, null, 'id', 'item')}</strong></small>
			</div>
		</div>;
	}

	render() {
		return (
			<div className="form-group row">
				<div className="col col-9 col-sm-10 col-md-11">
					<AutocompleteInput ref="searchInput"
						inputClassName="form-control"
						responseDataField="results"
						searchUrl={config.apiRoot+'/api/quicksearch?search=$s&quick=true'}
						onChange={this.inputChangeHandler}
						inputName="searchBoxInput"
						value={this.state.searchBoxInput}
						type="text"
						valueField="title"
						onEnter={this.executeSearch}
						onItemSelect={this.searchBoxItemSelectHandler}
						placeholder="Leit"
						containerClass="container"
						minChars={2}
						selectedItemClass="active"
						disableAutoFill={false}
						listLabelFormatFunc={this.formatAutocompleteList.bind(this)} />
					<div className="search-info d-none">Notaðu * og _ sem algildisstafi. Dæmi: aðal* og ma_ur</div>
				</div>
				<div className="col-2 col-md-1">
					<button onClick={this.executeSearch.bind(this)} className="btn btn-search"><span>Leita</span></button>
				</div>
			</div>
		);
	}
}

export default withRouter(SearchBox);
