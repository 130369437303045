import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PlayButton from './ui/PlayButton';
import LoadingBar from './ui/LoadingBar';
import ListFilter from './filters/ListFilter';
import PaginationButtons from './PaginationButtons';
import NoResults from './ui/NoResults';
import ListHeader from './ui/ListHeader';

import config from './../config';
import helpers from './../lib/helpers';
import MeterDisplay from './ui/MeterDisplay';

class MeterList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: null,
			nextUrl: null,
			previousUrl: null,
			resultCount: 0,
			loading: true
		}
	}

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.searchParams != prevProps.searchParams) {
			this.fetchData();
		}
	}

	fetchData() {
		this.setState({
			loading: true,
			data: null
		});

		let urlParams = [];

		if (this.props.searchParams) {
			let params = helpers.parseSearchParams(this.props.searchParams)

			if (params.heiti) {
				urlParams.push('name='+params.heiti)
			}

			if (params.sida) {
				urlParams.push('limit='+config.pageSize);
				urlParams.push('offset='+(config.pageSize*(params.sida-1)))
			}
		}

		helpers.fetch(config.apiRoot+'/api/meter/?'+urlParams.join('&'), (json) => {
			if (json) {
				this.setState({
					data: json.results,
					nextUrl: json.next,
					previousUrl: json.previous,
					resultCount: json.count
				});

				if (this.props.onResponse) {
					this.props.onResponse(json.results);
				}
			}
			this.setState({
				loading: false
			});
		});
	}

	render() {
		let data = this.state.data;

		return (
			<div className={'position-relative '+(this.props.className || '')+(this.state.loading ? ' loading' : '')}>
				<LoadingBar />
				{
					this.props.enableFilter &&
					<ListFilter filters={[
						{
							label: 'Heiti',
							paramName: 'heiti',
							type: 'text'
						}
					]}
					currentParams={this.props.searchParams}
					onFilter={(event) => {
						this.props.history.push(this.props.history.location.pathname+helpers.updateSearchParams(this.props.searchParams, event));
					}} />
				}
				{
					data && data.length > 0 &&
					<React.Fragment>
						{
							this.props.children
						}

						<ListHeader resultCount={this.state.resultCount} />

						<table className="table">
							<thead>
								<tr>
									<th>Bragarháttur</th>
									<th>Bragmynd</th>
									<th>Fjöldi erinda</th>
								</tr>
							</thead>
							<tbody>
								{
									data.map((item, index) => <tr key={index}>
										<td>
											{
												<Link to={helpers.routerUrls['bragarhaettir']+item.id}>{item.name || '(nafn vantar)'}</Link>
											}
										</td>
										<td>
											{
												item.kennistrengur && <MeterDisplay kennistrengur={item.kennistrengur} innrim={item.innrim} />
											}
											{item.kennistrengur}
										</td>
										<td>{item.stanza_count}</td>
									</tr>)
								}
							</tbody>
						</table>
						{
							this.state.resultCount > config.pageSize &&
							<PaginationButtons resultCount={this.state.resultCount}
								previousUrl={this.state.previousUrl}
								nextUrl={this.state.nextUrl} />
						}
					</React.Fragment>
				}
				{
					data && data.length == 0 && !this.state.loading &&
					<NoResults />
				}
			</div>
		)
	}
}

export default withRouter(MeterList);
