import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import LoadingBar from './ui/LoadingBar';
import MapView from './MapView';
import _ from 'underscore';

function LettersMap(props) {
	const [mapData, setMapData] = useState(null);
	const [lineData, setLineData] = useState(null);
	const location = useLocation();

	const history = useHistory();

	useEffect(() => {
		let m = [];

		let lines = [];

		if (props.mapData) {
			props.mapData.forEach(item => {
				if (item.dispatch_place && item.dispatch_place.lat && item.dispatch_place.lng) {
					m.push({
						id: item.dispatch_place.id,
						label: item.dispatch_place.name,
						latLng: [item.dispatch_place.lat, item.dispatch_place.lng]
					});
				}
				if (item.destination_place && item.destination_place.lat && item.destination_place.lng) {
					m.push({
						id: item.destination_place.id,
						label: item.destination_place.name,
						latLng: [item.destination_place.lat, item.destination_place.lng]
					});
				}

				if (item.dispatch_place && item.dispatch_place.lat && item.dispatch_place.lng && item.destination_place && item.destination_place.lat && item.destination_place.lng) {
					lines.push([[item.dispatch_place.lat, item.dispatch_place.lng], [item.destination_place.lat, item.destination_place.lng]])
				}
			});

			m = _.uniq(m, (item) => item.id);

			setMapData(m);
			setLineData(lines);
		}
	}, [props.mapData]);

	return (
		<div className={'position-relative '+(props.className || '')}>
			<LoadingBar />
			<MapView forceSmallMarkers={true} mapData={mapData || []} lineData={lineData} />
		</div>
	)
}

export default LettersMap;
