import React from 'react';
import helpers from '../../lib/helpers';

export default class PlayButton extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isPlaying: false
		};

		this.playButtonClickHandler = this.playButtonClickHandler.bind(this);
		this.audioPlayerPlayHandler = this.audioPlayerPlayHandler.bind(this);
		this.audioPlayerStopHandler = this.audioPlayerStopHandler.bind(this);
		this.play = this.play.bind(this);
	}

	audioPlayerPlayHandler() {
		this.setState({
			isPlaying: window.ismusAudioPlayer && window.ismusAudioPlayer.currentAudio && window.ismusAudioPlayer.currentAudio.id == this.props.itemId && window.ismusAudioPlayer.currentAudio.audioUrl == this.props.audioUrl && window.ismusAudioPlayer.currentAudio.playing
		});
	}

	audioPlayerStopHandler() {
		this.setState({
			isPlaying: window.ismusAudioPlayer && window.ismusAudioPlayer.currentAudio && window.ismusAudioPlayer.currentAudio.id == this.props.itemId && window.ismusAudioPlayer.currentAudio.audioUrl == this.props.audioUrl && window.ismusAudioPlayer.currentAudio.playing
		});
	}

	componentDidMount() {
		if (window.eventBus) {
			window.eventBus.addEventListener('audio.play', this.audioPlayerPlayHandler);
			window.eventBus.addEventListener('audio.stop', this.audioPlayerStopHandler);
			window.eventBus.addEventListener('audio.pauseaudio', this.audioPlayerStopHandler);
		}

		if (window.ismusAudioPlayer && window.ismusAudioPlayer.currentAudio && window.ismusAudioPlayer.currentAudio.id == this.props.itemId && (this.props.audioUrl && window.ismusAudioPlayer.currentAudio.audioUrl == this.props.audioUrl) && window.ismusAudioPlayer.currentAudio.playing) {
			this.setState({
				isPlaying: true
			});
		}
	}

	componentWillUnmount() {
		if (window.eventBus) {
			window.eventBus.removeEventListener('audio.play', this.audioPlayerPlayHandler);
			window.eventBus.removeEventListener('audio.stop', this.audioPlayerStopHandler);
			window.eventBus.removeEventListener('audio.pauseaudio', this.audioPlayerStopHandler);
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.itemId != prevProps.itemId) {
			this.setState({
				isPlaying: window.ismusAudioPlayer.currentAudio.id == this.props.itemId && window.ismusAudioPlayer.currentAudio.audioUrl == this.props.audioUrl && window.ismusAudioPlayer.currentAudio.playing
			});
		}
	}

	play() {
		if (window.eventBus) {
			if (window.ismusAudioPlayer && window.ismusAudioPlayer.currentAudio && window.ismusAudioPlayer.currentAudio.id == this.props.itemId && window.ismusAudioPlayer.currentAudio.audioUrl == this.props.audioUrl && window.ismusAudioPlayer.currentAudio.playing) {
				window.eventBus.dispatch('audio.pauseaudio');
			}
			else {
				let audioData = {
					id: this.props.itemId,
					title: this.props.itemTitle,
					info: this.props.itemInfo,
					audioUrl: this.props.audioUrl
				};

				if (this.props.dataTypeUrl) {
					audioData.dataTypeUrl = this.props.dataTypeUrl;
				}
				window.eventBus.dispatch('audio.playaudio', audioData);
			}
		}
	}

	playButtonClickHandler(event) {
		if (helpers.isVideoFile(this.props.audioUrl)) {
			if (this.props.onClick) {
				this.props.onClick(event);
			}

			return;
		}
		else if (this.props.disablePlayback || helpers.isVideoFile(this.props.audioUrl)) {
			return;
		}
		else {
			event.stopPropagation();
			event.preventDefault();

			this.play();
		}
		if (this.props.onClick) {
			this.props.onClick(event);
		}
	}

	render() {
		return <button alt="Spila"
			className={'play-button'+(helpers.isVideoFile(this.props.audioUrl) ? ' video' : '')+(this.state.isPlaying ? ' playing' : '')+(this.props.className ? ' '+this.props.className : '')}
			onClick={this.playButtonClickHandler}
		>
			<span>Spila</span>
			
			{
				helpers.isVideoFile(this.props.audioUrl) && <img src="/img/icon-video2.png" />
			}
		</button>;
	}
}
