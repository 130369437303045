import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import config from './../../config';
import helpers from './../../lib/helpers';

function DataCounter(props) {
	const [children, setChildren] = useState(null);

	useEffect(() => {
		fetchData();
	}, [props.url]);

	const fetchData = () => {
		helpers.fetch(config.apiRoot+props.url, (json) => {
			if (json) {
				if (props.onResponse) {
					setChildren(json.count > 0 ? props.onResponse(json.count) : null);
				}
			}
		});
	}

	return (
		children || null
	)
}

export default DataCounter;
