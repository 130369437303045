import React, { useRef } from 'react';
import { Link, withRouter } from "react-router-dom";

import config from './../../config.js';
import helpers from './../../lib/helpers.js';
//import Slider from './Slider.js';

function Progressbar(props) {
	const progressBar = useRef(false);

	return <div className="progress" ref={progressBar} onClick={(event) => {
		var rect = progressBar.current.getBoundingClientRect();

		var x = event.clientX-rect.left;
		var width = rect.width;

		if (props.onSelect) {
			props.onSelect(props.total*(x/width));
		}
	}}>
		<div className="progress-bar" style={{width: (props.progress && props.total ? (props.progress / props.total)*100 : 0)+'%'}} />
	</div>
}

class GlobalAudioPlayer extends React.Component {
	constructor(props) {
		super(props);

		this.audioCanPlayHandler = this.audioCanPlayHandler.bind(this);
		this.audioEndedHandler = this.audioEndedHandler.bind(this);
		this.audioPlayHandler = this.audioPlayHandler.bind(this);
		this.audioPauseHandler = this.audioPauseHandler.bind(this);
		this.durationSliderChangeHandler = this.durationSliderChangeHandler.bind(this);
		this.togglePlay = this.togglePlay.bind(this);

		this.state = {
			loaded: false,
			playing: false,
			paused: false,
			id: null,
			title: null,
			info: null,
			durationTime: 0,
			currentTime: 0
		};
	}

	componentDidMount() {
		window.ismusAudioPlayer = {
			player: this,
			currentAudio: {
				id: null,
				audioUrl: null,
				title: null,
				playing: false,
				paused: false
			}
		};

		this.audio = new Audio();

		this.audio.addEventListener('canplaythrough', this.audioCanPlayHandler);
		this.audio.addEventListener('ended', this.audioEndedHandler);
		this.audio.addEventListener('play', this.audioPlayHandler);
		this.audio.addEventListener('pause', this.audioPauseHandler);
		this.audio.addEventListener('error', this.audioErrorHandler);

		if (window.eventBus) {
			window.eventBus.addEventListener('audio.playaudio', function(event) {
				this.playAudio(event.target);
			}.bind(this));
			window.eventBus.addEventListener('audio.pauseaudio', function(event) {
				this.pauseAudio();
			}.bind(this));
			window.eventBus.addEventListener('audio.seek', (event) => {
				this.audio.currentTime = event.target;
			})
		}
	}

	audioCanPlayHandler(event) {
		if (this.state.loaded) {
			return;
		}

		this.setState({
			loaded: true
		});

		if (window.eventBus) {
			window.eventBus.dispatch('audio.playervisible');
		}

		this.audio.play();

		this.durationInterval = setInterval(function() {
			if (this.state.playing) {
				this.setState({
					currentTime: this.audio.currentTime,
					durationTime: this.audio.duration
				});
				window.eventBus.dispatch('audio.progress', this.audio.currentTime);
			}
		}.bind(this), 100);

		setTimeout(() => {
			this.initPhase = false;
		}, 1000);
	}

	audioEndedHandler(event) {
		window.ismusAudioPlayer.currentAudio.playing = false;
		window.ismusAudioPlayer.currentAudio.paused = false;

		if (window.eventBus) {
			window.eventBus.dispatch('audio.stop', {
				paused: false
			});
		}

		this.setState({
			paused: false,
			playing: false
		});

		clearInterval(this.durationInterval);
	}

	audioPlayHandler(event) {
		window.ismusAudioPlayer.currentAudio.playing = true;
		window.ismusAudioPlayer.currentAudio.paused = false;

		if (window.eventBus) {
			window.eventBus.dispatch('audio.play');
		}

		this.setState({
			playing: true,
			paused: false
		});
	}

	audioPauseHandler(event) {
		window.ismusAudioPlayer.currentAudio.playing = false;
		window.ismusAudioPlayer.currentAudio.paused = true;

		if (window.eventBus) {
			window.eventBus.dispatch('audio.stop', {
				paused: true
			});
		}

		this.setState({
			playing: false,
			paused: true
		});
	}

	audioErrorHandler(event) {
		if (window.eventBus) {
			//window.eventBus.dispatch('popup-notification.notify', null, l('Kan inte spela den här ljudfilen'));
		}
	}

	togglePlay() {
		if (this.state.loaded) {
			if (this.state.playing) {
				this.audio.pause();
			}
			else {
				this.audio.play();
			}
		}
	}

	pauseAudio() {
		if (this.state.loaded && this.state.playing) {
			this.audio.pause();
		}
	}

	resumeAudio() {
		if (this.state.loaded && this.state.paused) {
			this.audio.play();
		}
	}

	durationSliderChangeHandler(event) {
		if (!this.initPhase) {
			this.audio.currentTime = event.target.value[0];
		}
	}

	playAudio(data) {
		if (window.ismusAudioPlayer.currentAudio.id == data.id && window.ismusAudioPlayer.currentAudio.audio == data.audio && this.state.paused) {
			this.resumeAudio();
		}
		else {
			window.ismusAudioPlayer.currentAudio.id = data.id;
			window.ismusAudioPlayer.currentAudio.audioUrl = data.audioUrl;

			let stateObj = {
				loaded: false,
				playing: false,
				audioUrl: data.audioUrl,
				title: data.title,
				info: data.info,
				id: data.id
			};

			if (data.dataTypeUrl) {
				stateObj.dataTypeUrl = data.dataTypeUrl;
			}
			else {
				stateObj.dataTypeUrl = undefined;
			}

			this.setState(stateObj);

			this.initPhase = true;

			this.audio.src = data.audioUrl.startsWith('http://') || data.audioUrl.startsWith('https://') ? data.audioUrl : config.mediaUrl+data.audioUrl;
			this.audio.load();
		}
	}

	render() {
		return <div className={'global-audio-player-wrapper '+(this.state.loaded ? ' visible' : '')}>
			<div className={'global-audio-player'} disabled={!this.state.loaded}>

				<div className="player-time">
					{helpers.formatDuration(this.state.currentTime*1000)}
					<div className="duration">{helpers.formatDuration(this.state.durationTime*1000)}</div>
				</div>

				<div className="player-content">
					{
						(this.state.id || this.state.title) &&
						<div className="title">
							<h4>
								{
									this.state.id &&
									<Link target="_parent" to={(this.state.dataTypeUrl || '/tjodfraedi/hljodrit/')+this.state.id}>{this.state.title}</Link>
								}
								{
									!this.state.id &&
									this.state.title
								}
							</h4>
							{this.state.info && <small>{helpers.trim(this.state.info, 200)}</small>}
						</div>
					}
					<Progressbar progress={this.state.currentTime}
						total={this.state.durationTime}
						className="audio-seek-slider"
						onSelect={(event) => {
							if (!this.initPhase) {
								this.audio.currentTime = event;
							}
						}} />
					{/*<Slider className="audio-seek-slider" ref="slider" behaviour="tap-drag" start={0} rangeMin={0} rangeMax={this.state.durationTime} onChange={this.durationSliderChangeHandler} />*/}
				</div>

				<button className={'play-button large'+(this.state.playing ? ' playing' : '')} onClick={this.togglePlay}></button>

				<button className="btn close-button" onClick={() => {
					this.setState({
						loaded: false
					});

					this.pauseAudio();
				}}>
					<div className="close-icon white" />
				</button>

			</div>

		</div>;
	}
}

export default withRouter(GlobalAudioPlayer);
