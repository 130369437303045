import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PlayButton from './ui/PlayButton';
import LoadingBar from './ui/LoadingBar';
import ListFilter from './filters/ListFilter';
import PaginationButtons from './PaginationButtons';
import ListHeader from './ui/ListHeader';
import NoResults from './ui/NoResults';

import config from './../config';
import helpers from './../lib/helpers';

function PersonList(props) {
	const [data, setData] = useState(null);
	const [currentSearchTerm, setCurrentSearchTerm] = useState(null);
	const [nextUrl, setNextUrl] = useState(null);
	const [previousUrl, setPreviousUrl] = useState(null);
	const [resultCount, setResultCount] = useState(0);
	const [loading, setLoading] = useState(true);

	const history = useHistory();

	useEffect(() => {
		if (!data) {
			fetchData();
		}
	});

	useEffect(() => {
		fetchData();
	}, [props.locationId, props.searchParams]);

	const fetchData = () => {
		setLoading(true);
		setData(null);
		setCurrentSearchTerm(null)

		let urlParams = [];

		if (props.type) {
			urlParams.push('type='+props.type)
		}

		if (props.searchParams) {
			let params = helpers.parseSearchParams(props.searchParams)

			if (params.tegund) {
				urlParams.push('type='+params.tegund)
			}

			if (params.nafn) {
				urlParams.push('name='+params.nafn)
				setCurrentSearchTerm(params.nafn)
			}

			if (params.sida) {
				urlParams.push('limit='+config.pageSize);
				urlParams.push('offset='+(config.pageSize*(params.sida-1)))
			}
		}

		helpers.fetch(config.apiRoot+'/api/groups/?'+urlParams.join('&'), (json) => {
			setData(json.results);
			setLoading(false);
			setNextUrl(json.next);
			setPreviousUrl(json.previous);
			setResultCount(json.count);

			if (props.onResponse) {
				props.onResponse(json.results);
			}
		});
	}

	return (
		<React.Fragment>
			{
				props.enableFilter &&
				<ListFilter filters={[
					{
						label: 'Nafn',
						paramName: 'nafn',
						type: 'text'
					},
					{
						label: 'Tegund',
						paramName: 'tegund',
						type: 'select',
						selectOptions: {
							endpoint: 'grouptype',
							labelField: 'name',
							valueField: 'id'
						}
					}
				]}
				currentParams={props.searchParams}
				onFilter={(event) => {
					history.push(history.location.pathname+helpers.updateSearchParams(props.searchParams, event));
				}} />
			}
			<div className={'position-relative '+(props.className || '')+(loading ? ' loading' : '')}>
				<LoadingBar />
				{
					data && data.length > 0 &&
					<React.Fragment>
						{
							props.children
						}
						<ListHeader resultCount={resultCount} />
						<table className="table">
							<thead>
								<tr>
									<th>Nafn</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{
									data.map((item, index) => <tr key={index}>
										<td>
											<Link to={helpers.routerUrls['hopar']+item.id}>
												{
													item.name
												}
											</Link>
										</td>
										<td>
											{
												helpers.metadataList(item.type, helpers.routerUrls['hopar']+'?tegund=', 'id', 'name')
											}
										</td>
									</tr>)
								}
							</tbody>
						</table>
						{
							resultCount > config.pageSize &&
							<PaginationButtons resultCount={resultCount}
								previousUrl={previousUrl}
								nextUrl={nextUrl} />
						}
					</React.Fragment>
				}
				{
					data && data.length == 0 && !loading &&
					<NoResults searchTerm={currentSearchTerm} />
				}
			</div>
		</React.Fragment>
	)
}

export default PersonList;
