import React, { useState } from 'react';

function TextDisplay(props) {
	const [displayFull, setDisplayFull] = useState(false);
	const [open, setOpen] = useState(false);

	return (
		props.text ?
		<div style={props.maxHeight && !open ? { maxHeight: props.maxHeight } : null}
			className={'text-display '+(props.className || '')+(props.text.length > (props.maxLength || 1000) ? ' collapsible-content collapsible'+(open ? ' open' : '') : '')}
		>
			<p dangerouslySetInnerHTML={{__html: props.text}} />
			{
				props.text.length > (props.maxLength || 1000) && !open &&
				<div><button className="open-button btn btn-secondary btn-sm" onClick={() => setOpen(!open)}>{'Meira'}</button></div>
			}
		</div>
		: ''
	)
}

export default TextDisplay;
