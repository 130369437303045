import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PlayButton from './ui/PlayButton';
import LoadingBar from './ui/LoadingBar';
import ListFilter from './filters/ListFilter';
import PaginationButtons from './PaginationButtons';
import NoResults from './ui/NoResults';
import ListHeader from './ui/ListHeader';
//import TableRow from './ui/TableRow';

import config from './../config';
import helpers from './../lib/helpers';

function PersonList(props) {
	const [data, setData] = useState(null);
	const [currentSearchTerm, setCurrentSearchTerm] = useState(null);
	const [nextUrl, setNextUrl] = useState(null);
	const [previousUrl, setPreviousUrl] = useState(null);
	const [resultCount, setResultCount] = useState(0);
	const [loading, setLoading] = useState(false);

	const history = useHistory();

	useEffect(() => {
		if (!data && !props.data) {
			fetchData();
		}

		if (props.data) {
			setData(props.data);
		}
	});

	useEffect(() => {
		setData(props.data);
	}, [props.data]);

	useEffect(() => {
		fetchData();
	}, [props.locationId, props.searchParams, props.folkloreRecorderId]);

	const fetchData = () => {
		if (loading == true) {
			return;
		}

		setLoading(true);
		setData(null);
		setCurrentSearchTerm(null)

		let urlParams = [];

		if (props.locationId) {
			urlParams.push('location='+props.locationId)
		}

		if (props.folklore) {
			urlParams.push('folklore=true')
		}

		if (props.folkloreRecorderId) {
			urlParams.push('folklore_recorder='+props.folkloreRecorderId)
		}

		if (props.music) {
			urlParams.push('music=true')
		}

		if (props.searchParams) {
			let params = helpers.parseSearchParams(props.searchParams)

			if (params.nafn) {
				urlParams.push('name='+params.nafn)
				setCurrentSearchTerm(params.nafn)
			}

			if (params.stada) {
				urlParams.push('status='+params.stada)
			}

			if (params.stadsetning) {
				urlParams.push('location='+params.stadsetning)
				urlParams.push('lookup_parent=True')
			}

			if (params.sida) {
				urlParams.push('limit='+config.pageSize);
				urlParams.push('offset='+(config.pageSize*(params.sida-1)))
			}

		}

		helpers.fetch(config.apiRoot+'/api/persons/?'+urlParams.join('&'), (json) => {
			setData(json.results);
			setLoading(false);
			setNextUrl(json.next);
			setPreviousUrl(json.previous);
			setResultCount(json.count);

			if (props.onResponse) {
				props.onResponse(json.results);
			}
		});
	}

	return (
		<React.Fragment>
			{
				props.enableFilter &&
				<ListFilter filters={[
					{
						label: 'Nafn',
						paramName: 'nafn',
						type: 'text'
					},
					{
						label: 'Staða',
						paramName: 'stada',
						type: 'select',
						selectOptions: {
							endpoint: 'status',
							labelField: 'name',
							valueField: 'id'
						}
					},
					{
						label: 'Uppruni/heimili',
						paramName: 'stadsetning',
						type: 'multiselect',
						selectOptions: {
							endpoint: 'locations',
							labelField: 'name',
							valueField: 'id',
							filterParam: 'name_startswith',
							disableLargeList: true,
							listLabelFormatFunc: (item) => <div>
								{item.name}
								{
									(item.parent) &&
									<React.Fragment>
										<br/>
										<small>{helpers.createLocationParentString(item.parent)}</small>
									</React.Fragment>
								}
							</div>
						}
					}
				]}
				currentParams={props.searchParams}
				onFilter={(event) => {
					history.push(history.location.pathname+helpers.updateSearchParams(props.searchParams, event));
				}} />
			}
			<div className={'position-relative '+(props.className || '')+(loading ? ' loading' : '')}>
				<LoadingBar />
				{
					data && data.length > 0 &&
					<React.Fragment>
						{
							props.children
						}
						<ListHeader resultCount={resultCount} />
						<table className="table">
							<thead>
								<tr>
									<th>Nafn</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{
									data.map((item, index) => <tr key={index} hoverSettings={{
										endpoint: config.apiRoot+'/api/persons/'+item.id,
										fields: ['name', 'life'],
										template: '<strong>{name}</strong><br/>{life}'
									}}>
										<td>
											{
												item.file && item.file.path &&
												<img style={{
													maxWidth: '30px',
													maxHeight: '30px',
													marginRight: '20px'
												}} src={config.mediaUrl+item.file.path} />
											}
											<Link to={helpers.routerUrls['einstaklingar']+item.id}>
												{
													item.name
												}
											</Link>
										</td>
										<td>
											{
												(item.date_of_birth || item.date_of_death) &&
												<React.Fragment>{helpers.formatDate(item.date_of_birth, true)+'–'+helpers.formatDate(item.date_of_death, true)}</React.Fragment>
											}
										</td>
									</tr>)
								}
							</tbody>
						</table>
						{
							resultCount > config.pageSize && !props.data &&
							<PaginationButtons resultCount={resultCount}
								previousUrl={previousUrl}
								nextUrl={nextUrl} />
						}
					</React.Fragment>
				}
				{
					data && data.length == 0 && !loading &&
					<NoResults searchTerm={currentSearchTerm}>
						{
							props.folklore &&
							<p>Leit er bundin við þjóðfræðisafn, <Link to={helpers.routerUrls['einstaklingar']+props.searchParams}>villtu leita í öllum einstaklingum Ísmús?</Link></p>
						}
					</NoResults>
				}
			</div>
		</React.Fragment>
	)
}

export default PersonList;
