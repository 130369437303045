import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PlayButton from './ui/PlayButton';
import LoadingBar from './ui/LoadingBar';
import ListFilter from './filters/ListFilter';
import PaginationButtons from './PaginationButtons';
import NoResults from './ui/NoResults';
import DataCounter from './ui/DataCounter';
import TabContainer from './ui/TabContainer';
import ListHeader from './ui/ListHeader';
import AudioMap from './AudioMap';

import config from './../config';
import helpers from './../lib/helpers';

function AudioList(props) {
	const [data, setData] = useState(null);
	const [currentSearchTerm, setCurrentSearchTerm] = useState(null);
	const [nextUrl, setNextUrl] = useState(null);
	const [previousUrl, setPreviousUrl] = useState(null);
	const [resultCount, setResultCount] = useState(0);
	const [loading, setLoading] = useState(true);

	let history = useHistory();

	useEffect(() => {
		fetchData();
	}, [props.copyId, props.tapeId, props.heimildarmadur, props.locationId, props.typeId, props.keyId, props.stanzaId, props.searchParams, props.minnst_a_einstakling, props.minnst_a_stad]);

	const fetchData = (url) => {
		setLoading(true);
		setData(null);
		setCurrentSearchTerm(null)

		let urlParams = [];

		let params = props;

		if (props.searchParams) {
			params = {...params, ...helpers.parseSearchParams(props.searchParams)};
		}

		if (params.copyId) { // safnmark
			urlParams.push('copy='+params.copyId)
		}

		if (params.locationId) { // staðsetning
			urlParams.push('location='+params.locationId)
		}

		if (params.tapeId) { // frumgagn
			urlParams.push('tape='+params.tapeId)
		}

		if (params.texti) {
			urlParams.push('contents='+params.texti)
		}

		if (params.form) {
			urlParams.push('type='+params.form)
		}

		if (params.safnmark) { // safnmark
			urlParams.push('copy='+params.safnmark)
		}

		if (params.frumgagn) { // frumgagn
			urlParams.push('tape='+params.frumgagn)
		}

		if (params.samsafn) { // samsafn
			urlParams.push('collection='+params.samsafn)
		}

		if (params.efnisord) { // efnisorð
			urlParams.push('keyword='+params.efnisord)
		}

		if (params.sagnagerd) { // sagnagerð
			urlParams.push('key_name='+params.sagnagerd)
		}

		if (params.sagnagerdId) { // sagnagerð
			urlParams.push('key='+params.sagnagerdId)
		}

		if (params.hljodritunarstadur) { // sagnagerð
			urlParams.push('location='+params.hljodritunarstadur)
		}

		if (params.minnst_a_einstakling) { // minnst á einstakling
			urlParams.push('person_mention='+params.minnst_a_einstakling)
		}

		if (params.minnst_a_stad) { // minnst á stað
			urlParams.push('location_mention='+params.minnst_a_stad)
		}

		if (params.heimildarmadur) { // heimildarmaður
			if (helpers.isNumber(params.heimildarmadur)) {
				urlParams.push('source='+params.heimildarmadur)
			}
			else {
				urlParams.push('source_name='+params.heimildarmadur)
			}
		}

		if (params.heimildarmadur_kyn) { // kyn heimildarmanns
			urlParams.push('source_gender='+params.heimildarmadur_kyn)
		}

		if (params.heimildarmadur_uppruni) { // uppruni heimildarmanns
			urlParams.push('source_origin='+params.heimildarmadur_uppruni)
		}

		if (params.heimildarmadur_heimili) { // heimili heimildarmanns
			urlParams.push('source_address='+params.heimildarmadur_heimili)
		}

		if (params.heimildarmadur_stada) { // staða heimildarmanns
			urlParams.push('source_status='+params.heimildarmadur_stada)
		}

		if (params.hljodritari) { // samsafn
			if (helpers.isNumber(params.hljodritari)) {
				urlParams.push('interviewer='+params.hljodritari)
			}
			else {
				urlParams.push('interviewer_name='+params.hljodritari)
			}
		}

		if (params.hljodritari_kyn) { // kyn heimildarmanns
			urlParams.push('interviewer_gender='+params.hljodritari_kyn)
		}

		if (params.hljodritari_uppruni) { // uppruni heimildarmanns
			urlParams.push('interviewer_origin='+params.hljodritari_uppruni)
		}

		if (params.hljodritari_heimili) { // heimili heimildarmanns
			urlParams.push('interviewer_address='+params.hljodritari_heimili)
		}

		if (params.kvaedi) { // kvæði
			urlParams.push('poem='+params.kvaedi)
		}

		if (params.erindi) { // erindi
			urlParams.push('stanza='+params.erindi)
		}

		if (params.bragarhattur) { // bragarháttur
			urlParams.push('meter='+params.bragarhattur)
		}

		if (params.flutningsmati) { // flutningsmáti
			urlParams.push('performance_type='+params.flutningsmati)
		}

		if (params.tegund_erindis) { // flutningsmáti
			urlParams.push('stanza_type='+params.tegund_erindis)
		}

		if (params.hofundur_erindis) { // flutningsmáti
			urlParams.push('stanza_author='+params.hofundur_erindis)
		}

		if (params.stadir_minnst_a) { // minnst á í hljóðritum
			urlParams.push('location_mention='+params.stadir_minnst_a);
		}

		if (params.sida) {
			urlParams.push('limit='+config.pageSize);
			urlParams.push('offset='+(config.pageSize*(params.sida-1)))
		}

		setCurrentSearchTerm(params.texti)

		helpers.fetch(config.apiRoot+'/api/audio/?'+urlParams.join('&'), (json) => {
			if (json) {
				setData(json.results);
				setNextUrl(json.next);
				setPreviousUrl(json.previous);
				setResultCount(json.count);

				if (props.onResponse) {
					props.onResponse(json.results);
				}
			}
			setLoading(false);
		});
	}

	const displayListFilters = () => {
		let filterElements = [];

		let searchParams = helpers.parseSearchParams(props.searchParams)

		if (props.copyId || searchParams.safnmark) {
			filterElements.push(<span key={'safnmark'}>Safnmark: {data[0].copy.name}</span>);
		}

		if (props.tapeId || searchParams.frumgagn) {
			filterElements.push(<span key={'frumgagn'}>Frumgagn: {data[0].tape.name}</span>);
		}

		return filterElements.length > 0 ? <p key={'default'}>{filterElements}</p> : null;
	}

	let table = <React.Fragment>
		{
			data && data.length > 0 &&
			<React.Fragment>
				<table className="table">
					<thead>
						<tr>
							<th></th><th></th><th>{window.l('Efni')}</th><th>{window.l('Heimildarmenn')}</th><th>{window.l('Safnmark')}</th>
						</tr>
					</thead>
					<tbody>
						{
							data.map((item, index) => <tr key={index}>
								<td>
									{
										item.files && item.files.length > 0 &&
										<PlayButton className="small"
											audioUrl={item.files[0]}
											itemId={item.id}
											itemTitle={item.copy ? item.copy.name : ''}
											itemInfo={item.contents}
											onClick={() => {
												if (!props.disablePlayButtonRouteChange) {
													history.push(helpers.routerUrls['hljodrit']+item.id+(props.searchParams || ''));
												}
												if (helpers.isVideoFile(item.files[0])) {
													history.push(helpers.routerUrls['hljodrit']+item.id);
												}
											}} />
									}
								</td>
								<td>
									{
										item.duration && <small>{helpers.formatDuration(item.duration*1000)}</small>
									}
								</td>
								<td>
									<Link to={helpers.routerUrls['hljodrit']+item.id}>
										{
											props.hightlightId && props.hightlightId == item.id &&
											<strong>{helpers.trim(item.contents, 400)}</strong>
										}
										{
											(!props.hightlightId || props.hightlightId != item.id) &&
											<React.Fragment>{helpers.trim(item.contents, 400)}</React.Fragment>
										}
									</Link>
								</td>
								<td>{helpers.metadataList(item.source, helpers.routerUrls['einstaklingar'], 'id', 'name')}</td>
								<td>
									{item.copy ? item.copy.name : ''}
									<small className="float-right">{item.serial_number}</small>
								</td>
							</tr>)
						}
					</tbody>


				</table>
				{
					resultCount > config.pageSize &&
					<PaginationButtons resultCount={resultCount}
						previousUrl={previousUrl}
						nextUrl={nextUrl} />
				}
			</React.Fragment>
		}
	</React.Fragment>

	return (
		<React.Fragment>
			{
				props.enableFilter &&
				<ListFilter advanced={true} filters={[
					{
						label: window.l('Hljóðrit'),
						type: 'heading',
						includeInAdvanced: true
					},
					{
						label: window.l('Efni'),
						paramName: 'texti',
						type: 'text'
					},
					{
						label: window.l('Form'),
						paramName: 'form',
						type: 'multiselect',
						selectOptions: {
							endpoint: 'form',
							labelField: 'name',
							valueField: 'id',
							filterParam: 'name_startswith'
						},
						viewAllLink: helpers.routerUrls['form'],
						includeInAdvanced: true
					},
					{
						label: window.l('Safnmark'),
						paramName: 'safnmark',
						type: 'multiselect',
						selectOptions: {
							endpoint: 'copy',
							labelField: 'name',
							valueField: 'id',
							filterParam: 'name_startswith',
							singleSelect: true
						},
						viewAllLink: helpers.routerUrls['safnmark'],
						includeInAdvanced: true
					},
					{
						label: window.l('Frumgagn'),
						paramName: 'frumgagn',
						type: 'multiselect',
						selectOptions: {
							endpoint: 'tape',
							labelField: 'name',
							valueField: 'id',
							filterParam: 'name_startswith',
							singleSelect: true
						},
						viewAllLink: helpers.routerUrls['frumgagn'],
						includeInAdvanced: true
					},
					{
						label: window.l('Samsafn'),
						paramName: 'samsafn',
						type: 'select',
						selectOptions: {
							endpoint: 'collections',
							labelField: 'name',
							valueField: 'id'
						},
						includeInAdvanced: true
					},
					{
						label: window.l('Efnisorð'),
						paramName: 'efnisord',
						type: 'multiselect',
						selectOptions: {
							endpoint: 'keywords',
							labelField: 'name',
							valueField: 'id',
							filterParam: 'name_startswith'
						},
						viewAllLink: helpers.routerUrls['efnisord']
					},
					{
						label: window.l('Sagnagerð/minni'),
						paramName: 'sagnagerd',
						type: 'text',
						includeInAdvanced: true
					},
					/*
					{
						label: window.l('Sagnagerð/minni'),
						paramName: 'sagnagerd',
						type: 'multiselect',
						selectOptions: {
							endpoint: 'keys',
							labelField: 'name',
							valueField: 'id',
							filterParam: 'name_startswith',
							listLabelFormatFunc: (item) => <div>
								{item.name}
								{
									item.text &&
									<React.Fragment>
										<br/>
										<small>{item.text}</small>
									</React.Fragment>
								}
							</div>
						},
						viewAllLink: helpers.routerUrls['sagnagerd'],
						includeInAdvanced: true
					},
					*/
					{
						label: window.l('Hljóðritunarstaður'),
						paramName: 'hljodritunarstadur',
						type: 'multiselect',
						selectOptions: {
							endpoint: 'locations',
							labelField: 'name',
							valueField: 'id',
							filterParam: 'name_startswith',
							disableLargeList: true,
							listLabelFormatFunc: (item) => <div>
								{item.name}
								{
									(item.parent) &&
									<React.Fragment>
										<br/>
										<small>{helpers.createLocationParentString(item.parent)}</small>
									</React.Fragment>
								}
							</div>
						},
						viewAllLink: helpers.routerUrls['stadir']
					},
					{
						label: window.l('Staður sem minnst er á í hljóðriti'),
						paramName: 'stadir_minnst_a',
						type: 'multiselect',
						includeInAdvanced: true,
						selectOptions: {
							endpoint: 'locations',
							labelField: 'name',
							valueField: 'id',
							filterParam: 'name_startswith',
							disableLargeList: true,
							listLabelFormatFunc: (item) => <div>
								{item.name}
								{
									(item.parent) &&
									<React.Fragment>
										<br/>
										<small>{helpers.createLocationParentString(item.parent)}</small>
									</React.Fragment>
								}
							</div>
						},
						viewAllLink: helpers.routerUrls['stadir']
					},
					{
						label: window.l('Kvæði og erindi'),
						type: 'heading',
						includeInAdvanced: true
					},
					{
						label: window.l('Kvæði'),
						paramName: 'kvaedi',
						type: 'multiselect',
						selectOptions: {
							endpoint: 'poem',
							labelField: 'name',
							valueField: 'id',
							filterParam: 'name_startswith',
							singleSelect: true,
							disableLargeList: true
						},
						viewAllLink: helpers.routerUrls['kvaedi'],
						includeInAdvanced: true
					},
					{
						label: window.l('Erindi'),
						paramName: 'erindi',
						type: 'multiselect',
						selectOptions: {
							endpoint: 'stanza',
							labelField: 'first_line',
							valueField: 'id',
							filterParam: 'name_startswith',
							singleSelect: true,
							disableLargeList: true
						},
						viewAllLink: helpers.routerUrls['erindi'],
						includeInAdvanced: true
					},
					{
						label: window.l('Bragarháttur'),
						paramName: 'bragarhattur',
						type: 'multiselect',
						selectOptions: {
							endpoint: 'meter',
							labelField: 'name',
							valueField: 'id',
							filterParam: 'name_startswith'
						},
						includeInAdvanced: true
					},
					{
						label: window.l('Flutningsmáli'),
						paramName: 'flutningsmati',
						type: 'multiselect',
						selectOptions: {
							endpoint: 'performancetype',
							labelField: 'name',
							valueField: 'id',
							filterParam: 'name_startswith'
						},
						includeInAdvanced: true
					},
					{
						label: window.l('Tegund erindis'),
						paramName: 'tegund_erindis',
						type: 'multiselect',
						selectOptions: {
							endpoint: 'stanzatype',
							labelField: 'name',
							valueField: 'id',
							filterParam: 'name_startswith'
						},
						includeInAdvanced: true
					},
					{
						label: window.l('Höfundur erindis'),
						paramName: 'hofundur_erindis',
						type: 'multiselect',
						selectOptions: {
							endpoint: 'persons',
							labelField: 'name',
							valueField: 'id',
							filterParam: 'name_startswith',
							listLabelFormatFunc: (item) => <div>
								{item.name}
								{
									(item.date_of_birth || item.date_of_death || item.aka) &&
									<React.Fragment>
										<br/>
										<small>{item.aka && <em>{item.aka} </em>}{helpers.formatDate(item.date_of_birth, true)+'–'+helpers.formatDate(item.date_of_death, true)}</small>
									</React.Fragment>
								}
							</div>
						},
						viewAllLink: helpers.routerUrls['tjodfraedi_einstaklingar'],
						includeInAdvanced: true
					},
					{
						label: window.l('Heimildarmaður'),
						type: 'heading',
						includeInAdvanced: true
					},
					/*
					{
						label: window.l('Heimildarmaður'),
						paramName: 'heimildarmadur',
						type: 'text'
					},
					*/
					{
						label: window.l('Heimildarmaður'),
						paramName: 'heimildarmadur',
						type: 'multiselect',
						selectOptions: {
							endpoint: 'persons',
							labelField: 'name',
							valueField: 'id',
							filterParam: 'name_startswith',
							listLabelFormatFunc: (item) => <div>
								{item.name}
								{
									(item.date_of_birth || item.date_of_death || item.aka) &&
									<React.Fragment>
										<br/>
										<small>{item.aka && <em>{item.aka} </em>}{helpers.formatDate(item.date_of_birth, true)+'–'+helpers.formatDate(item.date_of_death, true)}</small>
									</React.Fragment>
								}
							</div>,
							enableTextInput: true
						},
						options: {
							extraParams: ['folklore=true']
						},
						viewAllLink: helpers.routerUrls['tjodfraedi_einstaklingar']
					},
					{
						label: window.l('Kyn'),
						paramName: 'heimildarmadur_kyn',
						type: 'select',
						selectOptions: {
							options: [
								{
									label: window.l('Karl'),
									value: 'Karl'
								},
								{
									label: window.l('Kona'),
									value: 'Kona'
								}
							]
						},
						includeInAdvanced: true
					},
					{
						label: window.l('Uppruni'),
						paramName: 'heimildarmadur_uppruni',
						type: 'multiselect',
						selectOptions: {
							endpoint: 'locations',
							labelField: 'name',
							valueField: 'id',
							filterParam: 'name_startswith',
							disableLargeList: true,
							listLabelFormatFunc: (item) => <div>
								{item.name}
								{
									(item.parent) &&
									<React.Fragment>
										<br/>
										<small>{helpers.createLocationParentString(item.parent)}</small>
									</React.Fragment>
								}
							</div>
						},
						viewAllLink: helpers.routerUrls['stadir'],
						includeInAdvanced: true
					},
					{
						label: window.l('Heimili'),
						paramName: 'heimildarmadur_heimili',
						type: 'multiselect',
						selectOptions: {
							endpoint: 'locations',
							labelField: 'name',
							valueField: 'id',
							filterParam: 'name_startswith',
							disableLargeList: true,
							listLabelFormatFunc: (item) => <div>
								{item.name}
								{
									(item.parent) &&
									<React.Fragment>
										<br/>
										<small>{helpers.createLocationParentString(item.parent)}</small>
									</React.Fragment>
								}
							</div>
						},
						viewAllLink: helpers.routerUrls['stadir'],
						includeInAdvanced: true
					},
					{
						label: window.l('Staða'),
						paramName: 'heimildarmadur_stada',
						type: 'multiselect',
						selectOptions: {
							endpoint: 'status',
							labelField: 'name',
							valueField: 'id',
							filterParam: 'name_startswith'
						},
						includeInAdvanced: true
					},
					{
						label: window.l('Hljóðritari/spyrill'),
						type: 'heading',
						includeInAdvanced: true
					},
					/*
					{
						label: window.l('Hljóðritari/spyrill'),
						paramName: 'hljodritari',
						type: 'text',
						includeInAdvanced: true
					},
					*/
					{
						label: window.l('Hljóðritari/spyrill'),
						paramName: 'hljodritari',
						type: 'multiselect',
						selectOptions: {
							endpoint: 'persons',
							labelField: 'name',
							valueField: 'id',
							filterParam: 'name_startswith',
							listLabelFormatFunc: (item) => <div>
								{item.name}
								{
									(item.date_of_birth || item.date_of_death || item.aka) &&
									<React.Fragment>
										<br/>
										<small>{item.aka && <em>{item.aka} </em>}{helpers.formatDate(item.date_of_birth, true)+'–'+helpers.formatDate(item.date_of_death, true)}</small>
									</React.Fragment>
								}
							</div>,
							enableTextInput: true
						},
						options: {
							extraParams: ['folklore=true']
						},
						viewAllLink: helpers.routerUrls['tjodfraedi_einstaklingar'],
						includeInAdvanced: true
					},
					{
						label: window.l('Kyn'),
						paramName: 'hljodritari_kyn',
						type: 'select',
						selectOptions: {
							options: [
								{
									label: window.l('Karl'),
									value: 'Karl'
								},
								{
									label: window.l('Kona'),
									value: 'Kona'
								}
							]
						},
						includeInAdvanced: true
					},
					{
						label: window.l('Uppruni'),
						paramName: 'hljodritari_uppruni',
						type: 'multiselect',
						selectOptions: {
							endpoint: 'locations',
							labelField: 'name',
							valueField: 'id',
							filterParam: 'name_startswith',
							disableLargeList: true,
							listLabelFormatFunc: (item) => <div>
								{item.name}
								{
									(item.parent) &&
									<React.Fragment>
										<br/>
										<small>{helpers.createLocationParentString(item.parent)}</small>
									</React.Fragment>
								}
							</div>
						},
						viewAllLink: helpers.routerUrls['stadir'],
						includeInAdvanced: true
					},
					{
						label: window.l('Heimili'),
						paramName: 'hljodritari_heimili',
						type: 'multiselect',
						selectOptions: {
							endpoint: 'locations',
							labelField: 'name',
							valueField: 'id',
							filterParam: 'name_startswith',
							disableLargeList: true,
							listLabelFormatFunc: (item) => <div>
								{item.name}
								{
									(item.parent) &&
									<React.Fragment>
										<br/>
										<small>{helpers.createLocationParentString(item.parent)}</small>
									</React.Fragment>
								}
							</div>
						},
						viewAllLink: helpers.routerUrls['stadir'],
						includeInAdvanced: true
					},
				]}
				currentParams={props.searchParams}
				onFilter={(event) => {
					history.push(history.location.pathname+helpers.updateSearchParams(props.searchParams, event));
				}} />
			}
			<div className={'position-relative '+(props.className || '')+(loading ? ' loading' : '')}>
				<LoadingBar />
				{
					data && data.length > 0 &&
					<React.Fragment>
						{
							props.children
						}
						<ListHeader resultCount={resultCount} />
						{
							displayListFilters()
						}
						{
							props.viewTabs &&
							<TabContainer useRouter={true} preserveSearchParams={true} selectedTab={props.match.params.tab} baseUrl={helpers.routerUrls['hljodrit']}>

								<div tabname="listi" tabtitle={'Hljóðrit'}>
									{
										table
									}
								</div>

								{
									<AudioMap tabname="kort" tabtitle={'Hljóðritunarstaðir'} searchParams={props.searchParams} />
								}

								{
									<AudioMap tabname="minnst_a_kort" tabtitle={'Staðir sem minnst er á'} searchParams={props.searchParams} mentions={true} />
								}

							</TabContainer>
						}
						{
							!props.viewTabs &&
							table
						}

					</React.Fragment>
				}
				{
					data && data.length == 0 && !loading &&
					<NoResults searchTerm={currentSearchTerm} />
				}
			</div>
		</React.Fragment>
	)
}

export default AudioList;
