import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PlayButton from './ui/PlayButton';
import LoadingBar from './ui/LoadingBar';
import ListFilter from './filters/ListFilter';
import PaginationButtons from './PaginationButtons';
import NoResults from './ui/NoResults';
import ListHeader from './ui/ListHeader';

import config from './../config';
import helpers from './../lib/helpers';

class StanzaList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: null,
			nextUrl: null,
			previousUrl: null,
			resultCount: 0,
			loading: true
		}
	}

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.authorId != prevProps.authorId ||
			this.props.poemId != prevProps.poemId ||
			this.props.book != prevProps.book ||
			this.props.searchParams != prevProps.searchParams || this.props.personId != prevProps.personId
		) {
			this.fetchData();
		}
	}

	fetchData() {
		this.setState({
			loading: true,
			data: null
		});

		let urlParams = [];

		if (this.props.authorId) {
			urlParams.push('author='+this.props.authorId);
		}

		if (this.props.poemId) {
			urlParams.push('poem='+this.props.poemId);
		}

		if (this.props.book) {
			urlParams.push('book='+this.props.book);
		}

		if (this.props.meterId) {
			urlParams.push('meter='+this.props.meterId);
		}

		if (this.props.searchParams) {
			let params = helpers.parseSearchParams(this.props.searchParams)

			if (params.fyrstalina) {
				urlParams.push('name='+params.fyrstalina)
			}

			if (params.efni) {
				urlParams.push('contents='+params.efni)
			}

			if (params.tegund) {
				urlParams.push('type='+params.tegund)
			}

			if (params.bragarhattur) {
				urlParams.push('meter='+params.bragarhattur)
			}

			if (params.hofundur) { // höfundur
				urlParams.push('author='+params.hofundur)
			}
	
			if (params.samsafn) { // samsafn
				urlParams.push('collection='+params.samsafn)
			}
	
			if (params.sida) {
				urlParams.push('limit='+config.pageSize);
				urlParams.push('offset='+(config.pageSize*(params.sida-1)))
			}
		}

		helpers.fetch(config.apiRoot+'/api/stanza/?'+urlParams.join('&'), (json) => {
			if (json) {
				this.setState({
					data: json.results,
					nextUrl: json.next,
					previousUrl: json.previous,
					resultCount: json.count
				});

				if (this.props.onResponse) {
					this.props.onResponse(json.results);
				}
			}
			this.setState({
				loading: false
			});
		});
	}

	render() {
		let data = this.state.data;

		return (
			<div className={'position-relative '+(this.props.className || '')+(this.state.loading ? ' loading' : '')}>
				<LoadingBar />
				{
					this.props.enableFilter &&
					<ListFilter filters={[
						{
							label: 'Fyrsta lína / heiti',
							paramName: 'fyrstalina',
							type: 'text'
						},
						{
							label: 'Vísutexti',
							paramName: 'efni',
							type: 'text'
						},
						{
							label: 'Bragarháttur',
							paramName: 'bragarhattur',
							type: 'select',
							selectOptions: {
								endpoint: 'meter',
								labelField: 'name',
								valueField: 'id'
							},
							options: {
								extraParams: ['limit=2000']
							},
							viewAllLink: helpers.routerUrls['bragarhaettir']
						},
						{
							label: 'Tegund',
							paramName: 'tegund',
							type: 'select',
							selectOptions: {
								endpoint: 'stanzatype',
								labelField: 'name',
								valueField: 'id'
							}
						},
						{
							label: window.l('Samsafn'),
							paramName: 'samsafn',
							type: 'select',
							selectOptions: {
								endpoint: 'collections',
								labelField: 'name',
								valueField: 'id'
							},
							options: {
								extraParams: ['type=6']
							}
						},
						{
							label: 'Höfundur',
							paramName: 'hofundur',
							type: 'multiselect',
							selectOptions: {
								endpoint: 'persons',
								labelField: 'name',
								valueField: 'id',
								filterParam: 'name_startswith',
								listLabelFormatFunc: (item) => <div>
									{item.name}
									{
										(item.date_of_birth || item.date_of_death) &&
										<React.Fragment>
											<br/>
											<small>{helpers.formatDate(item.date_of_birth, true)+'–'+helpers.formatDate(item.date_of_death, true)}</small>
										</React.Fragment>
									}
								</div>,
								enableTextInput: false
							}
						}
					]}
					currentParams={this.props.searchParams}
					onFilter={(event) => {
						this.props.history.push(this.props.history.location.pathname+helpers.updateSearchParams(this.props.searchParams, event));
					}} />
				}
				{
					data && data.length > 0 &&
					<React.Fragment>
						{
							this.props.children
						}

						<ListHeader resultCount={this.state.resultCount} />

						<table className="table">
							<thead>
								<tr>
									{
										!this.props.poemId &&
										<th>Kvæði</th>
									}
									{
										this.props.poemId &&
										<td></td>
									}
									<th>Fyrsta lína / heiti</th>
									<th>Höfundur</th>
									{
										!this.props.poemId &&
										<th>Safn</th>
									}
								</tr>
							</thead>
							<tbody>
								{
									data.map((item, index) => <tr key={index}>
										{
											!this.props.poemId && <td>
												{
													item.poem && <Link to={helpers.routerUrls['kvaedi']+item.poem.id}>{item.poem.name || item.poem.first_line}</Link>
												}
											</td>
										}
										{
											this.props.poemId && <td>
												{item.poem_order}{item.poem_order_letter}
											</td>
										}
										<td>
											<Link to={helpers.routerUrls['erindi']+item.id}>
												{
													item.name || item.first_line || '(vantar titil)'
												}
											</Link>
										</td>
										{
											true == false && !this.props.poemId && <td>
												{
													item.meter && <Link to={helpers.routerUrls['bragarhaettir']+item.meter.id}>{item.meter.name}</Link>
												}
											</td>
										}
										<td>
											{
												helpers.metadataList(item.author, helpers.routerUrls['einstaklingar'], 'id', 'name')
											}
										</td>
										{
											!this.props.poemId && <td>{helpers.metadataList(item.collections, helpers.routerUrls['erindi']+'?samsafn=', 'id', 'name')}</td>
										}
									</tr>)
								}
							</tbody>
						</table>
						{
							this.state.resultCount > config.pageSize &&
							<PaginationButtons resultCount={this.state.resultCount}
								previousUrl={this.state.previousUrl}
								nextUrl={this.state.nextUrl} />
						}
					</React.Fragment>
				}
				{
					data && data.length == 0 && !this.state.loading &&
					<NoResults />
				}
			</div>
		)
	}
}

export default withRouter(StanzaList);
