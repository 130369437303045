import React, { Component, useState, useEffect, useImperativeHandle } from 'react';
import { Link, withRouter } from 'react-router-dom';

import config from './../../config';
import helpers from './../../lib/helpers';
import MultiSelect from './MultiSelect';
import Slider from './../ui/Slider';
import MusicSheetInput from './MusicSheetInput';
import _ from 'underscore';

class Select extends Component {
	constructor(props) {
		super(props);

		this.state = {
			optionsData: null
		}
	}

	componentDidMount() {
		if (this.props.filter.selectOptions.endpoint) {
			fetch(config.apiRoot+'/api/'+this.props.filter.selectOptions.endpoint+'?limit=600'+(this.props.filter.options && this.props.filter.options.extraParams ? '&'+this.props.filter.options.extraParams.join('&') : ''))
				.then((res) => res.json())
				.then((json) => this.setState({
					optionsData: json.results
				}));
		}
		if (this.props.filter.selectOptions.options) {
			this.setState({
				optionsData: this.props.filter.selectOptions.options
			});
		}
	}

	render() {
		return <select className="form-control" value={this.props.value} onChange={this.props.onChange || undefined}>
			{
				!this.props.filter.disableNull &&
				<option value="-1"></option>
			}
			{
				this.state.optionsData &&
				this.state.optionsData.map((option, index) => <option selected={this.props.filter.default && this.props.filter.default == option[this.props.filter.selectOptions.valueField || 'value']} key={index} value={option[this.props.filter.selectOptions.valueField || 'value']}>{option[this.props.filter.selectOptions.labelField || 'label']}</option>)
			}
		</select>
	}
}

function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state

    return () => setValue(value => value + 1); // update the state to force render
}

const ListFilter = React.forwardRef((props, ref) => {
	const [formData, setFormData] = useState({});
	const [displayAdvancedForm, setDisplayAdvancedForm] = useState(false);

	const forceUpdate = useForceUpdate();

	useEffect(() => {
		let params = helpers.parseSearchParams(props.currentParams);

		let newFormData = Object.assign(formData, params);

		let propsInAdvanded = props.advanced ?
			_.find(props.filters, (filter) => {
				return filter.includeInAdvanced && newFormData[filter.paramName] !== null && newFormData[filter.paramName] !== undefined;
			}) != undefined
		: false;

		if (propsInAdvanded) {
			setDisplayAdvancedForm(true);
		}

		setFormData(newFormData);

		forceUpdate();
	}, [props.currentParams, props.filters]);

	const clearAdvancedFilters = () => {
		let newFormData = Object.assign(formData, {});

		props.filters.forEach((filter, i) => {
			if (filter.includeInAdvanced) {
				newFormData[filter.paramName] = undefined;
			}
		});

		setFormData(newFormData);
	}

	const setEventHandler = (type) => (event) => {
		let _formData = JSON.parse(JSON.stringify(formData));

		let value = event.target ? event.target.value : event.value;

		if (value && value != -1 && value != undefined) {
			_formData[type] = value;
		}
		else {
			_formData[type] = undefined;
		}

		setFormData(_formData);
	}

	let colSize = props.filters.length >= 6 ? 3 : 6;

	return (
		<div className="mt-2 mb-3 card gray-card">
			<div className="card-body">
				<h5 className="card-title">{props.cardTitle || window.l('Leit')}</h5>
				<div className="form-row">
					{
						props.filters.map((filter, index) => {
							return (!props.advanced || !filter.includeInAdvanced || (props.advanced && filter.includeInAdvanced && displayAdvancedForm)) ? <React.Fragment key={filter.paramName}>
								{
									filter.type == 'heading' &&
									<React.Fragment>
										{
											index > 0 && <hr className="col col-12" />
										}
										<h6 className="col col-12"><strong>{filter.label}</strong></h6>
									</React.Fragment>
								}
								{
									filter.type == 'divider' &&
									<div className={'mt-2 col-12 col-md-12'} />
								}
								{
									(filter.type != 'heading' && filter.type != 'divider') &&
									<div className={'mt-2 col-12 col-md-'+(filter.fullWidth ? 12 : colSize)}>
										<small><label>{filter.label}</label>{
											filter.viewAllLink &&
											<Link title="Sjá allt" className="ml-3 btn btn-light btn-sm float-right text-underline" to={filter.viewAllLink}>...</Link>
										}</small>
										{
											filter.type == 'text' &&
											<input type="text"
												className="form-control"
												placeholder={filter.label}
												value={formData[filter.paramName]}
												onKeyDown={(event) => {
													if (event.keyCode == 13) {
														if (props.onFilter) {
															props.onFilter(formData);
														}
													}
												}}
												onChange={setEventHandler(filter.paramName)}
											/>
										}
										{
											filter.type == 'select' &&
											<Select value={formData[filter.paramName]}
												filter={filter}
												onChange={setEventHandler(filter.paramName)}
											/>
										}
										{
											filter.type == 'multiselect' &&
											<MultiSelect value={formData[filter.paramName]}
												filter={filter}
												options={filter.selectOptions}
												onChange={setEventHandler(filter.paramName)}
											/>
										}
										{
											filter.type == 'daterange' &&
											<Slider className="slider-filter mb-4"
												format={{
													to: (value) => {
														//return helpers.formatDate((new Date(value)).toISOString().substring(0, 10), false, true, false);
														return (new Date(value)).toISOString().substring(0, 10);
													},
													from: Number
												}}
												rangeMin={(new Date('1840-01-01')).getTime()}
												rangeMax={(new Date('1890-01-01')).getTime()}
												onChange={setEventHandler(filter.paramName)}
											/>
										}{
											filter.type == 'musicsheet' &&
											<MusicSheetInput value={formData[filter.paramName]} 
												onChange={setEventHandler(filter.paramName)}
											/>
										}
									</div>
								}
							</React.Fragment> : null
						})
					}
				</div>
				<div className="mt-3">
					<button className="btn btn-primary" onClick={(event) => {
						if (props.onFilter) {
							let eventData = JSON.parse(JSON.stringify(formData));

							formData.sida = 1;

							props.onFilter(formData);
						}
					}}>{window.l('Leita')}</button>
					{
						props.advanced && <button className="ml-2 btn btn-secondary" onClick={() => {
							setDisplayAdvancedForm(!displayAdvancedForm);
							clearAdvancedFilters();
						}}>{props.advancedButtonLabel ? props.advancedButtonLabel : (displayAdvancedForm ? window.l('Loka ítarleit') : window.l('Opna ítarleit'))}</button>
					}
				</div>
			</div>
		</div>
	)
})

export default withRouter(ListFilter);
