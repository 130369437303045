import React, { useState, useEffect, Component } from 'react';

import { OpenSheetMusicDisplay, LinearTimingSource, PlaybackManager, BasicAudioPlayer } from 'osmd-extended';

import Soundfont from 'soundfont-player';

import _ from 'underscore';
import helpers from '../../lib/helpers';

class MusicSheetInput extends Component {
	allNotes = [
		{
			step: 'C',
			alter: 0
		},
		{
			step: 'C',
			alter: 1
		},
		{
			step: 'D',
			alter: 0
		},
		{
			step: 'D',
			alter: 1
		},
		{
			step: 'E',
			alter: 0
		},
		{
			step: 'F',
			alter: 0
		},
		{
			step: 'F',
			alter: 1
		},
		{
			step: 'G',
			alter: 0
		},
		{
			step: 'G',
			alter: 1
		},
		{
			step: 'A',
			alter: 0
		},
		{
			step: 'A',
			alter: 1
		},
		{
			step: 'B',
			alter: 0
		}
	];

	constructor(props) {
		super(props);

		this.state = {
			playing: false,
			selectedNotes: []
		};

		this.uniqueId = props.uniqueId || (new Date()).getTime();

		window.osmdInstances = window.osmdInstances || [];
	}

	initPlaybackManager() {
		var playbackListener = {
			play() {
				this.osmd.FollowCursor = true;
			},
			pause() {},
			reset() {},
			bpmChanged() {},
			volumeChanged() {},
			volumeMute() {},
			volumeUnmute() {}
		}

		this.timingSource = new LinearTimingSource();
		this.playbackManager = new PlaybackManager(this.timingSource, undefined, new BasicAudioPlayer(), undefined);
		this.playbackManager.DoPlayback = true;
		this.playbackManager.DoPreCount = false; 
		this.playbackManager.PreCountMeasures = 1; // note that DoPreCount has to be true for a precount to happen

		this.timingSource.reset();
		this.timingSource.pause();
		this.timingSource.Settings = this.osmd.Sheet.playbackSettings;
		this.playbackManager.initialize(this.osmd.Sheet.musicPartManager);
		this.playbackManager.addListener(this.osmd.cursor);
		this.playbackManager.reset();
		this.osmd.PlaybackManager = this.playbackManager;

		if (_.filter(window.osmdInstances, (item) => item.container.id == this.osmd.container.id) == 0) {
			window.osmdInstances.push(this.osmd);
		}
	}

	componentDidMount() {
		this.osmd = new OpenSheetMusicDisplay('sheetContainer'+this.uniqueId);

		this.osmd.setOptions({
			backend: 'canvas',
			drawTitle: false,
			drawingParameters: 'compacttight'
		});
	}

	componentWillUnmount() {
		try {
			this.osmd.PlaybackManager.pause();
		}
		catch(e) {}

		try {
			window.osmdInstances = window.osmdInstances.filter(item => item.container.id != this.osmd.container.id);

			this.osmd = null;

			delete this.osmd;
		}
		catch(e) {}
	}

	loadXml() {
		if (this.state.selectedNotes.length == 0) {
			return;
		}

		const notesXml = this.state.selectedNotes.map(note => {
			return `<note color="#000000" default-x="257" default-y="5">
				<pitch>
				<step>${note.step}</step>
				<alter>${note.alter}</alter>
				<octave>${note.octave}</octave>
				</pitch>
				<duration>256</duration>
				<instrument id="P1-I1" />
				<voice>1</voice>
				<type>quarter</type>
				<stem>up</stem>
				<staff>1</staff>
			</note>`
		}).join('');

		const emptyMusicXml = `<?xml version="1.0" encoding='UTF-8' standalone='no' ?>
			<!DOCTYPE score-partwise PUBLIC "-//Recordare//DTD MusicXML 3.0 Partwise//EN" "http://www.musicxml.org/dtds/partwise.dtd">
			<score-partwise version="3.0">

			<part-list>
			<score-part id="P1">
			<part-name> </part-name>
			<part-name-display>
				<display-text> </display-text>
			</part-name-display>
			<part-abbreviation> </part-abbreviation>
			<part-abbreviation-display>
				<display-text> </display-text>
			</part-abbreviation-display>
			<score-instrument id="P1-I1">
				<instrument-name> </instrument-name>
				<virtual-instrument>
				<virtual-library>General MIDI</virtual-library>
				<virtual-name>Bright Piano</virtual-name>
				</virtual-instrument>
			</score-instrument>
			</score-part>
			</part-list>
			<part id="P1">
			<!--============== Part: P1, Measure: 1 ==============-->
			<measure number="1" width="318">
			<print new-page="yes">
				<system-layout>
				<system-margins>
				<left-margin>22</left-margin>
				<right-margin>0</right-margin>
				</system-margins>
				<top-system-distance>182</top-system-distance>
				</system-layout>
			</print>
			<attributes>
				<divisions>256</divisions>

				<time symbol="common" color="#000000">
				<beats>4</beats>
				<beat-type>4</beat-type>
				</time>
				<staves>1</staves>
				<clef number="1" color="#000000">
				<sign>G</sign>
				<line>2</line>
				</clef>
			</attributes>

			${notesXml}

			</measure>

			</part>
		</score-partwise>`;
		
		this.stopAll();

		this.osmd.load(emptyMusicXml)
			.then(() => {
				this.osmd.render();

				if (this.osmd.Sheet) {
					this.initPlaybackManager();
				}
			}
		);
	}

	stopAll() {
		if (window.osmdInstances) {
			window.osmdInstances.forEach(osmd => {
				try {
					osmd.PlaybackManager.pause();
				}
				catch(e) {}
			});
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.value != prevProps.value) {
			this.setState({
				selectedNotes: helpers.stringToNotes(this.props.value)
			}, () => {
				this.loadXml();
			});
		}
	}

	changeEvent() {
		if (this.props.onChange) {
			this.props.onChange({
				value: helpers.notesToString(this.state.selectedNotes)});
		}
	}

	render() {
		return <div className="melody-search">
			<div className="buttons">
				<button disabled={this.state.selectedNotes.length == 0} alt="Spila" className={'play-button btn btn-secondary '+(this.state.playing ? ' playing' : '')} onClick={() => {
					if (this.osmd.PlaybackManager.isPlaying) {
						this.osmd.PlaybackManager.pause();
					}
					else {
						this.stopAll();

						this.osmd.PlaybackManager.play();
						this.setState({
							playing: true
						});
			
						this.playingCheck = setInterval(() => {
							try {
								if (!this.osmd.PlaybackManager.isPlaying) {
									clearInterval(this.playingCheck);
									this.setState({
										playing: false
									});
								}
							}
							catch(e) {
								if (this.playingCheck) {
									clearInterval(this.playingCheck);
								}
							}
						}, 500);
					}
				}}><span>Spila</span></button>

			<button disabled={this.state.selectedNotes.length < 1} className="btn btn-secondary" onClick={() => {
					let _selectedNotes = this.state.selectedNotes;
					_selectedNotes.splice(-1);

					this.setState({
						selectedNotes: _selectedNotes
					}, () => this.changeEvent());
				}}>Eyða síðustu nótu</button>

			<button className="btn btn-secondary" onClick={() => {
					this.setState({selectedNotes: []}, () => this.changeEvent());
				}}>Hreinsa</button>
			</div>
	
			<div className={'sheet-container'+(this.state.selectedNotes.length == 0 ? ' empty' : '')} id={'sheetContainer'+this.uniqueId} style={{minHeight: '100px'}}></div>

			<div className="piano-wrapper">
				<div className="piano-container">
					{
						[3, 4, 5, 6].map(octave => this.allNotes.map((note) => <a className={'note'+(note.alter == 1 ? ' half' : ' whole')} onClick={() => {
							let pianoNote = note.step+(note.alter == 1 ? '#' : '')+octave;

							if (this.state.piano) {
								this.state.piano.play(pianoNote);
							}
							else {
								Soundfont.instrument(new AudioContext(), 'acoustic_grand_piano').then((piano) => {
									piano.play(pianoNote);

									this.setState({
										piano: piano
									});
								});						
							}

							let selectedNotes = this.state.selectedNotes;

							selectedNotes.push({
								step: note.step,
								alter: note.alter,
								octave: octave
							});

							this.setState({
								selectedNotes: selectedNotes,
							}, () => {
								this.loadXml();
								this.changeEvent();
							});
						}}></a>))
					}
				</div>
			</div>
		</div>
	}
}

export default MusicSheetInput;
