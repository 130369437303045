import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import LoadingBar from './ui/LoadingBar';
import MapView from './MapView';

import config from './../config';
import helpers from './../lib/helpers';

function FolkloreMap(props) {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);

	const history = useHistory();

	useEffect(() => {
		if (!data) {
			fetchData();
		}
	});

	useEffect(() => {
		fetchData();
	}, [props.searchParams])

	const fetchData = () => {
		if (loading == true) {
			return;
		}

		setLoading(true);
		setData(null);

		let urlParams = [];

		if (props.searchParams != '') {
			let params = helpers.parseSearchParams(props.searchParams);

			if (params.copyId) { // safnmark
				urlParams.push('copy='+params.copyId)
			}

			if (params.locationId) { // staðsetning
				urlParams.push('location='+params.locationId)
			}

			if (params.tapeId) { // frumgagn
				urlParams.push('tape='+params.tapeId)
			}

			if (params.texti) {
				urlParams.push('audio_contents='+params.texti)
			}

			if (params.form) {
				urlParams.push('audio_type='+params.form)
			}

			if (params.safnmark) { // safnmark
				urlParams.push('audio_copy='+params.safnmark)
			}

			if (params.frumgagn) { // frumgagn
				urlParams.push('audio_tape='+params.frumgagn)
			}

			if (params.samsafn) { // samsafn
				urlParams.push('audio_collection='+params.samsafn)
			}

			if (params.efnisord) { // efnisorð
				urlParams.push('audio_keyword='+params.efnisord)
			}

			if (params.sagnagerd) { // sagnagerð
				urlParams.push('audio_key_name='+params.sagnagerd)
			}

			if (params.hljodritunarstadur) { // sagnagerð
				urlParams.push('audio_location='+params.hljodritunarstadur)
			}

			if (params.heimildarmadur) { // samsafn
				urlParams.push('audio_source_name='+params.heimildarmadur)
			}

			if (params.heimildarmadur_kyn) { // kyn heimildarmanns
				urlParams.push('audio_source_gender='+params.heimildarmadur_kyn)
			}

			if (params.heimildarmadur_uppruni) { // uppruni heimildarmanns
				urlParams.push('audio_source_origin='+params.heimildarmadur_uppruni)
			}

			if (params.heimildarmadur_heimili) { // heimili heimildarmanns
				urlParams.push('audio_source_address='+params.heimildarmadur_heimili)
			}

			if (params.heimildarmadur_stada) { // staða heimildarmanns
				urlParams.push('audio_source_status='+params.heimildarmadur_stada)
			}

			if (params.hljodritari) { // samsafn
				urlParams.push('audio_interviewer_name='+params.hljodritari)
			}

			if (params.hljodritari_kyn) { // kyn heimildarmanns
				urlParams.push('audio_interviewer_gender='+params.hljodritari_kyn)
			}

			if (params.hljodritari_uppruni) { // uppruni heimildarmanns
				urlParams.push('audio_interviewer_origin='+params.hljodritari_uppruni)
			}

			if (params.hljodritari_heimili) { // heimili heimildarmanns
				urlParams.push('audio_interviewer_address='+params.hljodritari_heimili)
			}

			if (params.kvaedi) { // kvæði
				urlParams.push('audio_poem='+params.kvaedi)
			}

			if (params.erindi) { // erindi
				urlParams.push('audio_stanza='+params.erindi)
			}

			if (params.bragarhattur) { // bragarháttur
				urlParams.push('audio_meter='+params.bragarhattur)
			}

			if (params.flutningsmati) { // flutningsmáti
				urlParams.push('audio_performance_type='+params.flutningsmati)
			}

			if (params.tegund_erindis) { // flutningsmáti
				urlParams.push('audio_stanza_type='+params.tegund_erindis)
			}

			if (params.hofundur_erindis) { // flutningsmáti
				urlParams.push('audio_stanza_author='+params.hofundur_erindis)
			}

			if (params.stadir_minnst_a) { // minnst á í hljóðritum
				urlParams.push('audio_location_mention='+params.stadir_minnst_a);
			}
		}

		urlParams.push('has_location=True');
		urlParams.push('simple=True');

		if (props.mentions) {
			urlParams.push('audio_mention=True');
		}
		else {
			urlParams.push('audio=True');
		}

		urlParams.push('limit=8000');

		helpers.fetch(config.apiRoot+'/api/locations/?'+urlParams.join('&'), (json) => {
			setData(json.results ? json.results.map((item) => {
				return {
					id: item.id,
					label: item.name,
					latLng: [item.lat, item.lng],
					count: item.relation_count,
					popupContent: '<h4>'+item.name+'</h4>'+
						'<p>'+
						(item.relation_count ? item.relation_count+' hljóðrit á þessum stað.</br><br/>' : '')+
						'<a href="'+helpers.routerUrls['location']+item.id+'">Nánar</a><br/>'+
						'<a href="'+helpers.routerUrls['hljodrit']+(helpers.updateSearchParams(props.searchParams || '', props.mentions ? {stadir_minnst_a: item.id} : {hljodritunarstadur: item.id}))+'">Bæta þessum stað við leit</a></p>'
				}
			}) : []);
			setLoading(false);

			if (props.onResponse) {
				props.onResponse(json.results);
			}
		});
	}

	return (
		<div className={'position-relative '+(props.className || '')+(loading ? ' loading' : '')}>
			<MapView mapData={data || []} />
		</div>
	)
}

export default FolkloreMap;
