import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {Helmet} from 'react-helmet';

import config from './../../config';
import helpers from './../../lib/helpers';

function Birthdays(props) {
	const [data, setData] = useState([]);

	useEffect(() => {
		if (data.length == 0) {
			helpers.fetch(config.apiRoot+'/api/persons/?limit=6&birthday=true', (json) => {
				setData(json.results);
			});
		}
	});

	return <div>
		{
			data.map((item, index) => {
				let year = item.date_of_birth.split('-')[0];

				return <p><Link to={helpers.routerUrls['person']+item.id}>{item.name}</Link> - {((new Date).getFullYear()-year)} ára
				</p>
			})
		}
	</div>
}

function NewMaterial(props) {
	const [data, setData] = useState([]);

	useEffect(() => {
		if (data.length == 0) {
			helpers.fetch(config.apiRoot+'/api/new_material/?limit=4', (json) => {
				setData(json.results);
			});
		}
	});

	return <div>
		{
			data.map((item, index) => <p><Link to={helpers.routerUrls[item.type]+item.id}>{item.name}</Link><br/>
				<small>{helpers.formatDate(item.creation_date, false, true)}</small>
			</p>)
		}
	</div>
}

function FrontPage(props) {
	const [pageParts, setPageParts] = useState(null);
	const [initialized, setInitialized] = useState(false);

	let history = useHistory();
	const location = useLocation();

	let isExternal = (url) => {
		var match = url.match(/^([^:\/?#]+:)?(?:\/\/([^\/?#]*))?([^?#]+)?(\?[^#]*)?(#.*)?/);
		if (url.toLowerCase().indexOf('.pdf') > -1) return true;
		if (url.startsWith('https://') || url.startsWith('http://')) return true;
		if (typeof match[1] === "string" && match[1].length > 0 && match[1].toLowerCase() !== window.location.protocol) return true;
		if (typeof match[2] === "string" && match[2].length > 0 && match[2].replace(new RegExp(":("+{"http:":80,"https:":443}[window.location.protocol]+")?$"), "") !== window.location.host) return true;
		return false;
	};

	let htmlContentClickHandler = (event) => {
		let linkEl = event.target.closest('a');

		if (linkEl) {
			let linkUrl = linkEl.getAttribute('href');

			if (!isExternal(linkUrl) && (!linkEl.getAttribute('target'))) {
				event.preventDefault();
				history.push(linkUrl);
			}
		}
	};

	useEffect(() => {
		helpers.fetch(config.apiRoot+'/api/pageparts/?page='+props.url, (json) => {
			setPageParts(json.results);

			setTimeout(() => setInitialized(true), 100);
		});

	}, [props.url]);

	console.log(location.pathname)

	return <div className="container wide-container">
		<div className={'row front-boxes'+(initialized ? ' initialized' : '')}>
			{
				pageParts && pageParts.length > 0 &&
				<Helmet>
					<meta property="og:type"
						content="article"
					/>
					<meta property="og:title"
						content={'Ísmús | '+pageParts[0].title}
					/>
					<meta property="og:description"
						content={helpers.trim(pageParts[0].content, 600)}
					/>
					<meta property="og:image" content='//ismus.is/img/share-image.png' />
				</Helmet>

			}
			{
				pageParts && pageParts.map((part, index) => {
					return <div key={index} className={'box '+part.classname}>
						<div className={'box-content '+(part.boxclassname || '')}>
							{
								part.className && part.classname.indexOf('theme-') > -1 &&
								<h2>{part.title}</h2>
							}
							{
								((part.className && part.classname.indexOf('theme-') == -1) || location.pathname == '/') &&
								<h4>{part.title}</h4>
							}
							{
								part.content == 'component:[nýtt_efni]' &&
								<NewMaterial />
							}
							{
								part.content == 'component:[afmæli]' &&
								<Birthdays />
							}
							{
								!part.content.startsWith('component:[') &&
								<div onClick={htmlContentClickHandler}
									dangerouslySetInnerHTML={{__html: part.content}} />
							}
						</div>
					</div>
				})
			}
		</div>
	</div>
}

export default FrontPage;
