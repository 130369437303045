import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import LoadingBar from './ui/LoadingBar';
import ListHeader from './ui/ListHeader';
import ListFilter from './filters/ListFilter';
import PaginationButtons from './PaginationButtons';
import NoResults from './ui/NoResults';
import _ from 'underscore';
import LettersMap from './LettersMap';

import config from './../config';
import helpers from './../lib/helpers';

const l = window.l;

function LettersList(props) {
	const [data, setData] = useState(null);
	const [mapData, setMapData] = useState(null);
	const [currentSearchTerm, setCurrentSearchTerm] = useState(null);
	const [nextUrl, setNextUrl] = useState(null);
	const [previousUrl, setPreviousUrl] = useState(null);
	const [resultCount, setResultCount] = useState(0);
	const [loading, setLoading] = useState(true);

	let history = useHistory();

	useEffect(() => {
		fetchData();
	}, [props.senderId, props.receiverId, props.personId, props.searchParams]);

	const fetchData = () => {
		setLoading(true);
		setData(null);

		let urlParams = [];

		let params = props;

		if (props.searchParams) {
			params = {...params, ...helpers.parseSearchParams(props.searchParams)};
		}

		if (params.senderId) { // sendandi
			urlParams.push('sender='+params.senderId)
		}

		if (params.receiverId) { // viðtakandi
			urlParams.push('receiver='+params.receiverId)
		}

		if (params.personId || params.einstaklingur) { // sendandi eða viðtakandi
			urlParams.push('person='+(params.personId || params.einstaklingur))
		}

		if (params.sendandi) {
			urlParams.push('sender='+params.sendandi)
		}

		if (params.mottakandi) {
			urlParams.push('receiver='+params.mottakandi)
		}

		if (params.dagsetning) {
			urlParams.push('daterange='+params.dagsetning)
		}

		if (params.samsafn) {
			urlParams.push('collection='+params.samsafn)
		}

		if (params.texti) {
			urlParams.push('text='+params.texti)
		}

		let mapUrlParams = [...urlParams];
		mapUrlParams.push('limit=2000')

		helpers.fetch(config.apiRoot+'/api/letters/locations?'+mapUrlParams.join('&'), (json) => {
			if (json) {
				setMapData(json.results);
			}
		});

		if (params.sida) {
			urlParams.push('limit='+config.pageSize);
			urlParams.push('offset='+(config.pageSize*(params.sida-1)))
		}

		helpers.fetch(config.apiRoot+'/api/letters/?'+urlParams.join('&'), (json) => {
			if (json) {
				setData(json.results);
				setNextUrl(json.next);
				setPreviousUrl(json.previous);
				setResultCount(json.count);

				if (props.onResponse) {
					props.onResponse(json.results);
				}
			}
			setLoading(false);
		});
	}

	return (
		<React.Fragment>
			{
				props.enableFilter &&
				<ListFilter filters={[
					{
						label: 'Innihald bréfs',
						paramName: 'texti',
						type: 'text'
					},
					{
						label: 'Sendandi',
						paramName: 'sendandi',
						type: 'multiselect',
						selectOptions: {
							endpoint: 'persons',
							labelField: 'name',
							valueField: 'id',
							filterParam: 'name_startswith',
							singleSelect: true,
							listLabelFormatFunc: (item) => <div>
								{item.name}
								{
									(item.date_of_birth || item.date_of_death || item.aka) &&
									<React.Fragment>
										<br/>
										<small>{item.aka && <em>{item.aka} </em>}{helpers.formatDate(item.date_of_birth, true)+'–'+helpers.formatDate(item.date_of_death, true)}</small>
									</React.Fragment>
								}
							</div>
						},
						options: {
							extraParams: ['letters=true']
						}
					},
					{
						label: 'Móttakandi',
						paramName: 'mottakandi',
						type: 'multiselect',
						selectOptions: {
							endpoint: 'persons',
							labelField: 'name',
							valueField: 'id',
							filterParam: 'name_startswith',
							singleSelect: true,
							listLabelFormatFunc: (item) => <div>
								{item.name}
								{
									(item.date_of_birth || item.date_of_death || item.aka) &&
									<React.Fragment>
										<br/>
										<small>{item.aka && <em>{item.aka} </em>}{helpers.formatDate(item.date_of_birth, true)+'–'+helpers.formatDate(item.date_of_death, true)}</small>
									</React.Fragment>
								}
							</div>
						},
						options: {
							extraParams: ['letters=true']
						}
					},
					{
						label: 'Sendandi eða móttakandi',
						paramName: 'einstaklingur',
						type: 'multiselect',
						selectOptions: {
							endpoint: 'persons',
							labelField: 'name',
							valueField: 'id',
							filterParam: 'name_startswith',
							singleSelect: true,
							listLabelFormatFunc: (item) => <div>
								{item.name}
								{
									(item.date_of_birth || item.date_of_death || item.aka) &&
									<React.Fragment>
										<br/>
										<small>{item.aka && <em>{item.aka} </em>}{helpers.formatDate(item.date_of_birth, true)+'–'+helpers.formatDate(item.date_of_death, true)}</small>
									</React.Fragment>
								}
							</div>
						},
						options: {
							extraParams: ['letters=true']
						}
					},
					{
						label: 'Samsafn',
						paramName: 'samsafn',
						type: 'select',
						selectOptions: {
							options: [
								{
									label: 'Bréfasafn Jóns Árnasonar',
									value: 2
								},
								{
									label: 'Bréfasafn Sigurðar Guðmundssonar málara',
									value: 3
								}
							]
						}
					},
					{
						type: 'divider'
					},
					{
						label: 'Dagsetning',
						paramName: 'dagsetning',
						type: 'daterange',
						rangeOptions: {

						}
					},
				]}
				currentParams={props.searchParams}
				onFilter={(event) => {
					history.push(history.location.pathname+helpers.updateSearchParams(props.searchParams, event));
				}} />
			}
			<div className={'position-relative '+(props.className || '')+(loading ? ' loading' : '')}>
				<LoadingBar />
				<LettersMap mapData={mapData} />
				{
					data && data.length > 0 &&
					<React.Fragment>
						{
							props.children
						}
						<ListHeader resultCount={resultCount} />
						<table className="table">
							<thead>
								<tr>
									<th>Dagsetning</th>
									<th>Sendandi</th>
									<th>Viðtakandi</th>
									<th>Samsafn</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{
									data.map((item, index) => <tr key={index}>
										<td>
											<Link to={helpers.routerUrls['bref']+item.id}>
												{item.dispatch_date ? helpers.formatDate(item.dispatch_date, item.dispatch_date_accuracy == 3) : 'Án dagsetningar'}
											</Link>
										</td>
										<td>{helpers.metadataList(_.where(item.persons, {role: 'sender'}).map(person => person.person), helpers.routerUrls['einstaklingar'], 'id', 'name')}</td>
										<td>{helpers.metadataList(_.where(item.persons, {role: 'receiver'}).map(person => person.person), helpers.routerUrls['einstaklingar'], 'id', 'name')}</td>
										<td>
											<Link to={helpers.routerUrls['bref']+'?samsafn='+item.collection.id}>{item.collection.name}</Link>
										</td>
										<td>
											{
												item.has_text &&
												<span title="Uppskrift" className="icon text mr-2" />
											}
											{
												item.url && item.url != '' &&
												<span title="Mynd" className="icon image mr-2" />
											}
										</td>
									</tr>)
								}
							</tbody>
						</table>
						{
							resultCount > config.pageSize &&
							<PaginationButtons resultCount={resultCount}
								previousUrl={previousUrl}
								nextUrl={nextUrl} />
						}
					</React.Fragment>
				}
				{
					data && data.length == 0 && !loading &&
					<NoResults searchTerm={currentSearchTerm} />
				}
			</div>
		</React.Fragment>
	)
}

export default LettersList;
