import React, { Component } from 'react';
import { NavLink, Link, withRouter } from "react-router-dom";

import config from './../../config';
import helpers from './../../lib/helpers';

class PageMenu extends Component {
	constructor(props) {
		super(props);

		this.url = config.apiRoot+'/api/pages/';

		this.state = {
			listData: []
		};
	}

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.site && prevProps.site != this.props.site) {
			this.fetchData();
		}
	}

	fetchData() {
		// Sæki gögn til APA
		this.setState({
			listData: []
		});

		fetch(this.url+'?site='+(this.props.site || 1))
			.then(function(response) {
				return response.json();
			})
			.then(function(json) {
				this.setState({
					listData: json.results
				});
			}.bind(this));
	}

	render() {
		return (
			<React.Fragment>
				<ul className={'menu-links'+(this.props.className ? ' '+this.props.className : '')}>
					{
						this.state.listData.length > 0 &&
						this.state.listData.map(function(item, index) {
							return <li key={index} className={item.menu_separator ? ' separator' : ''}>
								<NavLink exact to={item.url}>{item.title}</NavLink>
							</li>;
						}.bind(this))
					}
					<li className="float-right">
						<NavLink to={helpers.routerUrls['saveditems']}>{helpers.urlTitles['saveditems']}</NavLink>
					</li>

				</ul>
			</React.Fragment>
		);
	}
}

export default withRouter(PageMenu);
