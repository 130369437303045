import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import LoadingBar from './ui/LoadingBar';
import MapView from './MapView';

import config from './../config';
import helpers from './../lib/helpers';

function ThingMap(props) {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);

	const history = useHistory();

	useEffect(() => {
		if (!data) {
			fetchData();
		}
	});

	useEffect(() => {
		fetchData();
	}, [props.searchParams])

	const fetchData = () => {
		setLoading(true);
		setData(null);

		let urlParams = [];

		if (props.searchParams != '') {
			let params = helpers.parseSearchParams(props.searchParams);

			if (params.nafn) {
				urlParams.push('thing_name='+params.nafn)
			}

			if (params.tegund) {
				urlParams.push('thing_brand='+params.tegund)
			}
		}

		urlParams.push('has_location=True');
		urlParams.push('simple=True');
		urlParams.push('thing=True');
		urlParams.push('limit=8000');

		fetch(config.apiRoot+'/api/locations/?'+urlParams.join('&'))
			.then((result) => result.json())
			.then((json) => {
				setData(json.results.map((item) => {
					return {
						id: item.id,
						label: item.name,
						latLng: [item.lat, item.lng]
					}
				}));
				setLoading(false);

				if (props.onResponse) {
					props.onResponse(json.results);
				}
			});
	}

	return (
		<div className={'position-relative '+(props.className || '')+(loading ? ' loading' : '')}>
			<MapView mapData={data || []} />
		</div>
	)
}

export default ThingMap;
