import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import L from 'leaflet'
import 'leaflet.markercluster';
import 'leaflet.heat';
import _ from 'underscore';
import helpers from '../lib/helpers';

class MapView extends Component {
	constructor(props) {
		super(props);

		this.mapContainer = React.createRef();
		this.icelandCenter = [64.963051, -19.020836];

		this.markerClusterSettings = {
			maxClusterRadius: 40
		};

		this.state = {
		};

		this.mapMoveEnd = this.mapMoveEnd.bind(this);
	}

	componentDidMount() {
		this.layerLmiGrunnkort = L.tileLayer.wms('https://gis.lmi.is/mapcache/web-mercator?', {
			layers: 'LMI_Kort',
			format: 'image/png',
			attribution: 'Landmælingar Íslands',
			//crs: 'EPSG:4326',
			transparent: 'true'
		});

		this.layerLmiOrnefni = L.tileLayer.wms('https://gis.lmi.is/geoserver/wms?', {
			layers: 'Ornefni_an_mannvirkja',
			format: 'image/png',
			attribution: 'Landmælingar Íslands',
			//crs: 'EPSG:4326',
			transparent: 'true'
		});

		this.layerOpenStreetMap = L.tileLayer('https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', {
			maxZoom: 19,
			attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Tiles style by <a href="https://www.hotosm.org/" target="_blank">Humanitarian OpenStreetMap Team</a> hosted by <a href="https://openstreetmap.fr/" target="_blank">OpenStreetMap France</a>'
		});

		this.esriWorldImagery = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
			attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
		});

		this.layerLmiKort = L.layerGroup([this.layerLmiGrunnkort, this.layerLmiOrnefni]);

		let baseLayers = {
			'ESRI loftmynd': this.esriWorldImagery,
			'LMÍ grunnkort': this.layerLmiKort,
			'Open Street Map': this.layerOpenStreetMap,
		};

		this.markersClusterLayer = L.markerClusterGroup(this.markerClusterSettings);
		this.markersLayer = L.featureGroup();
		this.linesLayer = L.polyline([], {
			color: '#ffffff',
			weight: 1
		});

		this.heatLayer = L.heatLayer([], {
			minOpacity: 0.35,
			radius:18,
			blur:15
		});

		let overlayLayers = {
			'Punktar': this.markersClusterLayer,
			'Smápunktar': this.markersLayer,
			'Hitakort': this.heatLayer
		};

		this.map = L.map(this.mapContainer.current, {
			minZoom: 2,
			maxZoom: 16,
			layers: this.props.forceSmallMarkers ? [this.esriWorldImagery, this.markersLayer] : [this.esriWorldImagery, this.markersClusterLayer]
		}).setView(this.icelandCenter, 5);

		L.control.layers(baseLayers, overlayLayers).addTo(this.map);

		this.linesLayer.addTo(this.map);

		this.markers = [];

		if (this.props.mapData && this.props.mapData.length > 0) {
			this.addMarkers(this.props.mapData);
		}

		if (this.props.lineData && this.props.lineData.length > 0) {
			this.addLines(this.props.lineData);
		}

		this.map.on('moveend', this.mapMoveEnd);
	}

	mapMoveEnd() {
		if (this.props.onMoveEnd) {
			this.props.onMoveEnd({
				center: this.map.getCenter(),
				zoom: this.map.getZoom()
			});
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.mapData && this.props.mapData != prevProps.mapData) {
			this.addMarkers(this.props.mapData);
		}
		if (this.props.lineData && this.props.lineData != prevProps.lineData) {
			this.addLines(this.props.lineData);
		}
		if (this.props.setMapView && this.props.setMapView != prevProps.setMapView) {

			this.map.off('moveend', this.mapMoveEnd);
			this.map.setView(this.props.setMapView.center, this.props.setMapView.zoom);
			setTimeout(() => {
				this.map.on('moveend', this.mapMoveEnd);
			}, 300);
		}
	}

	addLines(data) {
		this.linesLayer.setLatLngs(data)
	}

	addMarkers(data) {
		this.markersClusterLayer.clearLayers();
		this.markersLayer.clearLayers();
		this.markers = [];

		let layerGroup = this.markersClusterLayer;

		let bounds = [];
		let heatPoints = [];

		data.map((item) => {
			try {
				let options = {
					title: item.label
				};

				let bindPopup = (m) => m.bindPopup(
					item.popupContent || '<h4>'+item.label+(item.description ? '<br/><small>'+item.description+'</small>' : '')+'</h4>'+
					'<p>'+
					(item.district ? item.district+'<br/>' : '')+
					'<a href="'+helpers.routerUrls['location']+item.id+'">Nánar</a></p>'
				).bindTooltip(item.label);

				let marker = L.marker(item.latLng, options);
				bindPopup(marker);

				let smallMarker = L.circleMarker(item.latLng, {
					radius: 5,
					weight: 2,
					fillColor: '#71b9ff',
					fillOpacity: 1,
					color: '#fff'
				})
				bindPopup(smallMarker);

				bounds.push(item.latLng);
				heatPoints.push([item.latLng[0], item.latLng[1], item.count || 20]);

				layerGroup.addLayer(marker);
				this.markersLayer.addLayer(smallMarker);
				this.markers.push({
					id: item.id,
					type: item.type,
					marker: marker
				});
			}
			catch(e) {
				//console.log(e)
			}
		});

		try {
			this.map.fitBounds(bounds, {
				maxZoom: 10,
				padding: [20, 20]
			});
		}
		catch(e) {}

		try {
			this.heatLayer.setLatLngs(heatPoints);
		}
		catch(e) {}

		this.updateMapSize();
	}

	updateMapSize() {
		this.map.invalidateSize();
	}

	render() {
		return (
			<div className={'map-wrapper '+(this.props.className || '')}>
				<div ref={this.mapContainer} className="map-container" />
			</div>
		);
	}
}

export default MapView;
