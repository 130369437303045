import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PlayButton from './ui/PlayButton';
import LoadingBar from './ui/LoadingBar';
import ListFilter from './filters/ListFilter';
import PaginationButtons from './PaginationButtons';
import NoResults from './ui/NoResults';

import config from './../config';
import helpers from './../lib/helpers';

function BookList(props) {
	const [data, setData] = useState(null);
	const [nextUrl, setNextUrl] = useState(null);
	const [previousUrl, setPreviousUrl] = useState(null);
	const [resultCount, setResultCount] = useState(0);
	const [loading, setLoading] = useState(true);

	const history = useHistory();

	useEffect(() => {
		if (!data) {
			fetchData();
		}
	});

	useEffect(() => {
		fetchData();
	}, [props.locationId, props.searchParams]);

	const fetchData = () => {
		setLoading(true);
		setData(null);

		let urlParams = [];

		if (props.authorId) {
			urlParams.push('author='+props.authorId);
		}

		if (props.searchParams) {
			let params = helpers.parseSearchParams(props.searchParams)

			if (params.nafn) {
				urlParams.push('name='+params.nafn)
			}

			if (params.tegund) {
				urlParams.push('script_type='+params.tegund)
			}

			if (params.sida) {
				urlParams.push('limit='+config.pageSize);
				urlParams.push('offset='+(config.pageSize*(params.sida-1)))
			}
		}

		helpers.fetch(config.apiRoot+'/api/book/?'+urlParams.join('&'), (json) => {
			if (json) {
				setData(json.results);
				setNextUrl(json.next);
				setPreviousUrl(json.previous);
				setResultCount(json.count);

				if (props.onResponse) {
					props.onResponse(json.results);
				}
			}
			setLoading(false);
		});
	}

	return (
		<div className={'position-relative '+(props.className || '')+(loading ? ' loading' : '')}>
			<LoadingBar />
			{
				props.enableFilter &&
				<ListFilter filters={[
					{
						label: 'Heiti',
						paramName: 'nafn',
						type: 'text'
					},
					{
						label: 'Tegund',
						paramName: 'tegund',
						type: 'select',
						selectOptions: {
							endpoint: 'scripttype',
							labelField: 'name',
							valueField: 'id'
						}
					}
				]}
				currentParams={props.searchParams}
				onFilter={(event) => {
					history.push(history.location.pathname+helpers.updateSearchParams(props.searchParams, event));
				}} />
			}
			{
				data && data.length > 0 &&
				<React.Fragment>
					{
						props.children
					}
					<table className="table">
						<thead>
							<tr>
								<th>Safnmark</th>
								<th>Heiti</th>
								<th>Aldur</th>
							</tr>
						</thead>
						<tbody>
							{
								data.map((item, index) => <tr key={index}>
									<td>
										<Link to={helpers.routerUrls['baekur']+item.id}>
											{
												(item.name == item.number ? '' : item.number)
											}
										</Link>
									</td>
									<td>
										<Link to={helpers.routerUrls['baekur']+item.id}>
											{
												item.name
											}
										</Link>
									</td>
									<td>{item.dating}</td>
								</tr>)
							}
						</tbody>
					</table>
					{
						resultCount > config.pageSize &&
						<PaginationButtons resultCount={resultCount}
							previousUrl={previousUrl}
							nextUrl={nextUrl} />
					}
				</React.Fragment>
			}
			{
				data && data.length == 0 && !loading &&
				<NoResults />
			}
		</div>
	)
}

export default BookList;
