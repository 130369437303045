import React, { useState, useEffect } from 'react';
import _ from 'underscore';

import helpers from '../../lib/helpers';

class nyMynd {
    ErLoggildurStrengur(kennistrengur = "") {
        const Stodur = Object.freeze({
            "uppHaf": 1, "bragfjoldi": 2, "rim": 3, "jafngilding": 4
        });
        let stada = Stodur.uppHaf;
        kennistrengur = kennistrengur.split("");
        let i = -1;
        for (let stafur of kennistrengur) {
            i++;
            switch (stada) {
                case Stodur.uppHaf: {
                    if (stafur === "h" || stafur === "H" || stafur === "s" || stafur === "S") {
                        break;
                    }
                    if (helpers.isNumber(stafur)) {
                        stada = Stodur.bragfjoldi;
                        break;
                    }
                    if (stafur === "=") {
                        stafur = Stodur.jafngilding;
                        break;
                    }
                    return [false, "UPPHAF i"];
                    break;
                }
                case Stodur.bragfjoldi: {
                    if (helpers.isNumber(stafur) || stafur == "?" || stafur == "-" || stafur == "!") {
                        break;
                    }
                    let gildirStafir = "qwertyuioplkjhgfdsazxcvbnmðæþöéýúíóáþQWERTYUIOPASDFGHJKLZXCVBNMÞÆÐÉÝÚÍÁÖ";
                    if (gildirStafir.indexOf(stafur) > -1) {
                        stada = Stodur.rim;
                        break;
                    }
                    if (stafur == ":") {
                        stada = Stodur.uppHaf;
                        break;
                    }
                    return [false, "BRAGFJÖLDI"];
                    break;
                }
                case Stodur.rim: {
                    if (helpers.isNumber(stafur)) {
                        stada = Stodur.bragfjoldi;
                        break;
                    }
                    let gildirStafir = "qwertyuioplkjhgfdsazxcvbnmðæþöéýúíóáþQWERTYUIOPASDFGHJKLZXCVBNMÞÆÐÉÝÚÍÁÖ";
                    if (gildirStafir.indexOf(stafur) > -1) {
                        break;
                    }
                    if (stafur == ":") {
                        stada = Stodur.uppHaf;
                        break;
                    }
                    return [false, "RÍMUS i"];
                }
                case Stodur: jafngilding: {
                    if (helpers.isNumber(stafur)) {
                        break;
                    }
                    if (stafur == ":") {
                        stada = Stodur.uppHaf;
                        break;
                    }
                    return [false, "Jafngilding i"];
                }
            }
        }
        if (stada == Stodur.uppHaf) {
            return [false, "Rangstaða i"];
        }
        return [true, "Löggilt!"];
    }
    /*
	tekur inn kennistreng og skilar html mynd af þeim bragarhætti. 
	Villumeldir ekki. Rusl inn, Rusl út. 
*/

    nyBragMynd(kennistrengur = "") {
        let ret = "<div class='bs_bragmynd'>";
        let linur = this.nyBragMyndFylki(kennistrengur);
        for(let lina of linur){
            ret += lina;
        }
        ret += "</div>";
        return ret;
    }

    /*
	Tekur inn kennistreng og skilar fylki með html hverrar braglínu.
	Hvert stak í fylkinu er ein lína. 
    */
    nyBragMyndFylki(kennistrengur = "") {
        let linustrengir = kennistrengur.split(":");
        let linur = [];
        let litir = this.Litafylki(50);
        for (let i = 0; i < linustrengir.length; i++) {
            let lina = linustrengir[i];
            let ut = [];
            ut = lina.match(/=[0-9]*/);
            if (ut && ut.length > 0) { //Afrita línuna sem =x bendir á. 
                let fj = ut[0][1].split("=");
                linur.push(linur[fj - 1]);
            } else {
                linur.push(this.myndaLinu(lina, litir));
            }
        }

        return linur;
    }


    /*
        Tekur inn streng einnar línu, ásamt litafylki, og skilar html mynd af þeirri línu.
    */
    myndaLinu(linustrengur, litir) {
        let ret = "<div class='bs_braglina'>";
        let s = "";
        let parsing = false;
        let first = true;
        for (let takn of linustrengur) {
            if (helpers.isNumber(takn)) { //Bragfjöldi
                parsing = true;
                if (!first) {
                    ret += this.myndabraglid(s, litir);
                    s = takn;
                } else { //Rím
                    s = takn;
                    first = false;
                }
            } else {
                if (first) {//'s' og 'h' í upphafi strengs. 
                    continue;
                } else {
                    s += takn; //Fyrsta tala
                }
            }
        }
        if (parsing) {
            ret += this.myndabraglid(s, litir);
        }
        ret += "</div>";
        return ret;
    }

    myndabraglid(bragstrengur, litir) {
        let falid = "";
        let fj = bragstrengur[0];
        let rim = bragstrengur.split(fj);
        let rimFylki = [];
        let forlidur = false;
        let ret = "";
        if (rim[1].length > 0) { //Það er annaðhvort rím á þessum bragstreng eða forliðamerki
            if (rim[1][0] === "!" || rim[1][0] == "-" || rim[1][0] == "?") {
                forlidur = (rim[1][0] === "!") ? 2:1;

                if (rim[1][0] == "-") {
                    falid = " bs_falid";
                }
                rim[1] = rim[1].substr(1, rim[1].length - 1);
                if (rim[1].length > 0) {
                    rimFylki = this.rimstrengurTilFylkis(rim[1], litir);
                    rim = true;
                }
            } else {
                rimFylki = this.rimstrengurTilFylkis(rim[1], litir);
                rim = true;
            }
        } else { //Bara tala. 
            rim = false;
        }
        ret = `<span class='bs_braglidur ${falid}'>`;
        for (let i = 0; i < fj; i++) {
            ret += this.myndaAtkvaedi(rimFylki, i,forlidur);
        }

        ret += "</span>";
        return ret;
    }

    myndaAtkvaedi(litaFylki, i, forlidur = 0) {
        let ret;
        let classes = "bs_atkvaedi";
        forlidur = (forlidur == 2) ? "<b class = 'bs_forlidur'>o</b>" : (forlidur == 1) ? "<b class = 'bs_forlidur'>(o)</b>" : "<b></b>";
        if (i >= litaFylki.length) {
            ret = `<span class='${classes}'>${forlidur}</span>`;
        } else {
            let r = litaFylki[i][0];
            let g = litaFylki[i][1];
            let b = litaFylki[i][2];
            ret = `<span class='${classes}' style='background-color:rgb(${r},${g},${b})'>${forlidur}</span>`;
        }
        return ret;
    }


/*
	Tekur rímnastreng (I.e Bókstafi) og litafylki, og skilar fylki af litum sem passar við þann streng.
	T.d ef strengurinn Aba birtist og litirnir [[255,0,0],[0,0,255]] mun fallið skila 
	[[255,0,0],[255,0,0],[0,0,255],[255,0,0]]: Eða Rautt, Rautt, Blátt, Rautt - eins og rímið sagði. 
*/
    rimstrengurTilFylkis(rimnastrengur, litir) {
        if (rimnastrengur.length == 0) {
            return [];
        }
        let litur;
        let rimFylki = [];
        let gildirStafir = "abcdefghijklmnpqrstuvwxyzþæðöáéíóúý";
        for (let stafur of rimnastrengur) {
            if (stafur.toLowerCase() === "o") {
                litur = [255, 255, 255];
            }
            else {
                let i = gildirStafir.indexOf(stafur.toLowerCase());
                litur = litir[i];
            }
            if (stafur == stafur.toLowerCase()) { //eitt atkvæði
                rimFylki.push(litur);
            } else { //Tvö atkvæði
                rimFylki.push(litur);
                rimFylki.push(litur);
            }
        }
        return rimFylki;
    }



    Litafylki(n) {
        //Setur upphafs litinn h,s,l og skil milli aukalita
        let skip = (1 / 6) * 360;
        let h = 0;
        let start = 0;
        let s = 100;
        let l = 50;
        let ret = [];
        for (let i = 0; i < n; i++) {
            if (i == 0) {
                ret[i] = this.HslToRgb(h, s, l);
                continue;
            }
            //Hoppa þriðjung um hring
            h -= 360 / 3;
            h = ((h % 360) + 360) % 360;
            if (i % 3 == 0) {
                //Færa okkur ögn af þriðjungnum
                h += skip;
            }
            if (i % 6 == 0) {
                //Deyfa litinn 
                s -= 25;
                if (s <= 0) {
                    s += 95;
                }
            }
            if (i % 9 == 0) {
                //Dekkja litinn 
                l -= 25;
                if (l <= 15) {
                    l += 60;
                }
            }
            ret[i] = this.HslToRgb(h, s-30, l+20);
        }
        return ret;
    }



    HslToRgb(h, s, l) {
        //Hvað mig varðar eru þetta töfrar. Það er best bara að láta þetta vera
        //Ég tók jöfnurnar af netinu og þær virðast virka.
        s /= 100;
        l /= 100;
        h = h % 360;
        let c = (1 - Math.abs(2 * l - 1)) * s;
        let x = c * (1 - Math.abs(((h / 60) % 2) - 1));
        let m = l - (c / 2);
        let rgb = [];
        if (h < 60) {
            rgb = [c, x, 0];
        } else if (h < 120) {
            rgb = [x, c, 0];
        } else if (h < 180) {
            rgb = [0, c, x];
        } else if (h < 240) {
            rgb = [0, x, c];
        } else if (h < 300) {
            rgb = [x, 0, c];
        } else {
            rgb = [c, 0, x];
        }
        rgb[0] = (rgb[0] + m) * 255;
        rgb[1] = (rgb[1] + m) * 255;
        rgb[2] = (rgb[2] + m) * 255;
        return rgb;
    }
}

class Bragmynd {

    constructor() {
        this.myndari = new nyMynd();
        this.f_gerd = { 'ekkert': 1, 'svigi': 2, 'hornklofi': 3, 'fastur': 4 };
    }

    nyBragmyndFylki(kennistrengur = "", innrim = "") {
        let ks = this.gamlaTilNyja(kennistrengur, innrim);
        return this.myndari.nyBragMyndFylki(ks);
    }

    nyBragMynd(kennistrengur = "", innrim = "") {
        let ks = this.gamlaTilNyja(kennistrengur, innrim);
        return this.myndari.nyBragMynd(ks);
    }

    gamlaTilNyja(kennistrengur = "", innrim = "") {
        let ks = "";
        let linur = [];
        let hlutar = kennistrengur.split(":"); //["4l","(o)-xx","4,4,4,5","AaBAB"]
		if(hlutar.length < 4){
			return;
		}
		
        let braglidir = hlutar[2];
        let rim = hlutar[3];
        let taktur = hlutar[1]; //(o)-xx
        let afjoldi = this.teljaAtkvaedi(taktur);
        let forlidaGerd = this.forlidagerd(taktur);
        let stafrof = "abcdefghijklmnopqrstuvwxyzþæöáéíúóð".split("");

        let bragSett = braglidir.split(",");
        let stifsett = [];
        let endaRimsett = [];
        for (let i = 0; i < rim.length; i++) {
            let stafur = rim[i];
			stafrof.splice(stafrof.indexOf(stafur.toLowerCase()),1);
            if (stafur == stafur.toLowerCase()) {
                stifsett.push(1);
                endaRimsett.push(stafur);
            } else {
                if (i + 1 != rim.length) {
                    if (rim[i + 1] === ".") {
                        stifsett.push(3); ///Þriggja atkvæða Rím
                        endaRimsett.push(stafur + rim[i].toLowerCase());
                        i++;
                    } else {
						stifsett.push(2); //Tveggja atkvæða rím
						endaRimsett.push(stafur);
					}
				} else {
					stifsett.push(2); //Tveggja atkvæða rím
					endaRimsett.push(stafur);
				}	
			} 
        }

        let innrimsDict = {};
        if (innrim.length > 1) {
            innrimsDict = this.vinnaInnrim(innrim);
        }

        for (let j = 0; j < bragSett.length; j++) {
            let lina = bragSett[j];
            let linstrengur = "";
            if (lina[0] == "o") {
                linstrengur = "1-";
                lina = lina.substr(1);
            } 
            for (let i = 0; i < lina; i++) {
                let forlidastrengur = "";
                if (i == 0) {
                    switch (forlidaGerd) {
                        case this.f_gerd.ekkert:{
                            break;
                        }
                        case this.f_gerd.fastur: {
                            linstrengur += "1!";
                            break;
                        }
                        case this.f_gerd.hornklofi: {
                            break;
                        }
                        case this.f_gerd.svigi: {
                            linstrengur += "1-";
                            break;
                        }
                    }
                }
                if ((i + 1) == lina) {
                    linstrengur += stifsett[j];
                    linstrengur += endaRimsett[j];
                } else {
                    linstrengur += afjoldi;
                }
                let l = j + 1;
                if (l in innrimsDict) {
                    if (i in innrimsDict[l]) {
                        let s = stafrof[innrimsDict[l][i]];
                        linstrengur += s;
                    }
                }
            }
            linur.push(linstrengur);
        }
        for (let lina of linur) {
            ks += lina + ":";
        }
        ks = ks.slice(0, ks.length - 1);
        return ks;
    }

    vinnaInnrim(innrim) {
        let strengir = "abcdefghijklmnopqrstuvwxyzþæöáéíúóð";
        let nRim = 0;
        let ret = {};
        let litir = innrim.split(";");
        for (let rim of litir) {
            let systkin = rim.split(",");
            for (let atkv of systkin) {
                let l, b, n;
                l = atkv.match(/[0-9]+/g)[0];
                b = atkv.match(/[A-Z]+/g)[0];
                if (b.length > 1) {
                    n = 0;
                    for (let x = 0; x < b.length; x++) {
                        let m = strengir.indexOf(b[x].toLowerCase());
                        n += (b.length - x) * strengir.length;
                    }
                } else {
                    n = strengir.indexOf(b.toLowerCase());
                }
                if (!(l in ret)) {
                    ret[l] = {};
                } 
                ret[l][n] = nRim;
            }
            nRim++;
        }
        return ret;
    }
    
    teljaAtkvaedi(taktstrengur = "") {
        let a = 0;
        for (let i = 0; i < taktstrengur.length; i++) {
            if (taktstrengur[i] == "[") {
                while (i < taktstrengur.length && taktstrengur[i] != "]") {
                    i++;
                }
            }

            if (taktstrengur[i] == "(") {
                while (i < taktstrengur.length && taktstrengur[i] != ")") {
                    i++;
                }
            }
            if (taktstrengur[i] == "x" || taktstrengur[i] == "-") {
                a++;
            }
        }
        return a;
    }
    
    forlidagerd(taktur = "") {
        for (let i = 0; i < taktur.length; i++) {
            if (taktur[i].toLowerCase() == "o") {
                return this.f_gerd.fastur;
            }
            if (taktur[i] == "(") {
                if (taktur[i + 1].toLowerCase() == "o") {
                    return this.f_gerd.svigi;
                }
            }
            if (taktur[i] == "[") {
                if (taktur[i + 1].toLowerCase() == "o") {
                    return this.f_gerd.hornklofi;
                }
            }
        }
        return this.f_gerd.ekkert;
    }

}

function MeterDisplay(props) {
	const [bragmyndHtml, setBragmyndHtml] = useState(null);
	useEffect(() => {
		let bragmynd = new Bragmynd();
		let html = bragmynd.nyBragMynd(props.kennistrengur, props.innrim || '');

		setBragmyndHtml(html)
	}, [props.kennistrengur, props.innrim]);


	return bragmyndHtml ? <div className="meter-display">
		<div dangerouslySetInnerHTML={{__html: bragmyndHtml}} />
	</div> : null
}

export default MeterDisplay;
