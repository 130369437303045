import React, { useState, useEffect } from 'react';

function SiteFooter(props) {
	return (
		<div className="site-footer">
			<div className={'container'+(props.frontPage ? ' wide-container' : '')}>
				<div className="row">

					<div className="col-12 col-md-4">

						<div className="footer-content">
							<p><strong>Ísmús</strong><br/>
								Arngrímsgötu 3<br/>
								107 Reykjavík<br/>
								<a href="mailto: ismus@ismus.is">ismus@ismus.is</a>
							</p>
						</div>

					</div>

					<div className="col-12 col-md-8">
						<div className="footer-content footer-logos float-right">
							<a href="https://arnastofnun.is"><img src="/img/logo-arnastofnun.png" alt="Stofnun Árna Magnússonar í íslenskum fræðum" /></a>
							<a href="https://tonlistarsafn.is"><img src="/img/logo-tonlistarsafn.png" alt="Tónlistarsafn Íslands" /></a>
							<a href="https://landsbokasafn.is"><img src="/img/logo-landsbokasafn.png" alt="Landsbókasafn Íslands" /></a>
						</div>
					</div>

				</div>
			</div>
		</div>
	)
}

export default SiteFooter;
