import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";

import BookmarkButton from './BookmarkButton';
import PlayButton from './PlayButton';
import localLibrary from './../../lib/localLibrary';
import helpers from './../../lib/helpers';

class SavedItemsView extends Component {
	constructor(props) {
		super(props);
	}

	formatDate(date) {
		let d = new Date(date);
		return d.getDay()+'.'+(d.getMonth()+1)+'.'+d.getFullYear();
	}

	render() {
		let savedItems = localLibrary.list();

		return (
			<div className="content-container">
				<div className={'row'}>

					<div className={'col col-12'}>
						<h3>Handraðinn</h3>
						<hr/>

						{
							savedItems && savedItems.length > 0 &&
							<React.Fragment>
								<p>
									<button className="btn btn-secondary btn-sm" onClick={() => localLibrary.clear()}>Tæma lista</button>
								</p>
								<table className="table">
									<thead>
										<tr>
											<th></th><th>Titill</th><th>Tegund</th><th></th>
										</tr>
									</thead>
									<tbody>
										{
											savedItems.map((item, index) => <tr key={index}>
												<td>
													{
														item.audioUrl &&
														<PlayButton className="small"
															audioUrl={item.audioUrl}
															itemId={item.id}
															itemTitle={item.title}
															itemInfo={item.info || ''} />
													}
												</td>
												<td key={index}><Link to={helpers.routerUrls[item.type]+item.id}>{item.title}</Link>{
													item.info &&
													<React.Fragment>
														<br/><small>{helpers.trim(item.info, 120)}</small>
													</React.Fragment>
												}</td>
												<td>{helpers.types[item.type]}</td>
												<td><small>Bætt við {this.formatDate(item._date_added)}</small><BookmarkButton className="small float-right" {...item} /></td>
											</tr>)
										}
									</tbody>
								</table>
							</React.Fragment>
						}

						{
							(!savedItems || savedItems.length == 0) &&
							<p>Þú hefur ekki vistað neitt efni.<br/><br/>Við flestar tegundir efnis má finna hnapp í formi stjörnu. Með honum er hægt að geyma efni sem mun birtast hér á þessari síðu.</p>
						}

					</div>

				</div>

			</div>
		);
	}
}

export default withRouter(SavedItemsView);
