import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import TabContainer from './ui/TabContainer';
import TextDisplay from './ui/TextDisplay';
import Breadcrumb from './ui/Breadcrumb';
import MeterDisplay from './ui/MeterDisplay';
import BookmarkButton from './ui/BookmarkButton';
import LinkedItems from './ui/LinkedItems';
import _ from 'underscore';

import config from './../config';
import helpers from './../lib/helpers';
import StanzaList from './StanzaList';

function MeterView(props) {
	const [data, setData] = useState(null);
	const history = useHistory();

	useEffect(() => {
		if (props.match.params.id) {

			helpers.fetch(config.apiRoot+'/api/meter/'+props.match.params.id, (json) => {
				setData(json);
			}, null, 'name');
		}
	}, [props.match.params.id]);

	return (

			data &&
			<div className="content-container">

				<Breadcrumb currentElTitle={data.name} />

				<div>
					<BookmarkButton id={data.id} title={data.name} type="stanza" className="float-right" />
					<h2>{data.name}</h2>
				</div>

				<div className="row mt-5">
					<div className="col-12 col-md-7">
						{
							data.text && <div className="mb-5">
								<h4>Lýsing</h4>
								<p dangerouslySetInnerHTML={{__html: data.text}} />
							</div>
						}
						{
							data.example && <div>
								<h4>Dæmi</h4>
								<p dangerouslySetInnerHTML={{__html: data.example}} />
							</div>
						}
					</div>

					<div className="col-12 col-md-5">

						<table className="table metadata-table">
							<tbody>
								{
									data.kennistrengur && <tr><td width="30%"><label>Kennistrengur</label></td><td>{data.kennistrengur}</td></tr>
								}
								{
									data.innrim && <tr><td width="30%"><label>Innrím</label></td><td>{data.innrim}</td></tr>
								}
								{
									data.kennistrengur && <>
										<tr>
											<td colSpan={2}>
												<label className="mb-2">Bragmynd</label>
												<MeterDisplay kennistrengur={data.kennistrengur} innrim={data.innrim} />
											</td>
										</tr>
									</>
								}
							</tbody>
						</table>
					</div>
				</div>

				<div className="row">

					<div className="col-12 col-md-8">
						{
							data.contents && <div className="card mt-4">
								<div className="card-body">
									<TextDisplay className="text-larger" text={data.contents} />
								</div>
							</div>
						}

						{
							data.info && <TextDisplay className="mt-4" text={data.info} />
						}


					</div>

					<div className="col-12 col-md-4">

						<table className="table metadata-table">
							<tbody>
							</tbody>
						</table>

					</div>

				</div>

				<TabContainer useRouter={'true'} selectedTab={props.match.params.tab} baseUrl={helpers.routerUrls['erindi']+props.match.params.id}>
					<StanzaList meterId={props.match.params.id}
						searchParams={props.searchParams}
						tabname="erindi"
						tabtitle={'Erindi'}
					 >
						<p className="mb-3">Erindi í þessum bragarhætti.</p>
					 </StanzaList>
				</TabContainer>

			</div>

	)
}

export default MeterView;
