import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import LoadingBar from './ui/LoadingBar';
import ListFilter from './filters/ListFilter';
import PaginationButtons from './PaginationButtons';
import NoResults from './ui/NoResults';
import ListHeader from './ui/ListHeader';

import config from './../config';
import helpers from './../lib/helpers';

function InterviewList(props) {
	const [data, setData] = useState(null);
	const [nextUrl, setNextUrl] = useState(null);
	const [previousUrl, setPreviousUrl] = useState(null);
	const [resultCount, setResultCount] = useState(0);
	const [loading, setLoading] = useState(true);

	let history = useHistory();

	useEffect(() => {
		fetchData();
	}, [props.locationId, props.searchParams]);

	const fetchData = () => {
		setLoading(true);
		setData(null);

		let urlParams = [];

		if (props.sourceId) { // viðmælandi
			urlParams.push('source='+props.sourceId)
		}

		if (props.locationId) { // staðsetning
			urlParams.push('location='+props.locationId)
		}

		if (props.searchParams) {
			let params = helpers.parseSearchParams(props.searchParams)

			if (params.nafn) {
				urlParams.push('name='+params.nafn)
			}

			if (params.safn) {
				urlParams.push('collection='+params.safn)
			}

			if (params.sida) {
				urlParams.push('limit='+config.pageSize);
				urlParams.push('offset='+(config.pageSize*(params.sida-1)))
			}
		}

		helpers.fetch(config.apiRoot+'/api/interview/?'+urlParams.join('&'), (json) => {
			if (json) {
				setData(json.results);
				setNextUrl(json.next);
				setPreviousUrl(json.previous);
				setResultCount(json.count);

				if (props.onResponse) {
					props.onResponse(json.results);
				}
			}
			setLoading(false);
		});
	}
	return (
		<React.Fragment>
			{
				props.enableFilter &&
				<ListFilter filters={[
					{
						label: 'Nafn',
						paramName: 'nafn',
						type: 'text'
					},
					{
						label: 'Safn',
						paramName: 'safn',
						type: 'select',
						selectOptions: {
							endpoint: 'collections',
							labelField: 'name',
							valueField: 'id'
						}
					}
				]}
				currentParams={props.searchParams}
				onFilter={(event) => {
					history.push(history.location.pathname+helpers.updateSearchParams(props.searchParams, event));
				}} />
			}
			<div className={'position-relative '+(props.className || '')+(loading ? ' loading' : '')}>
				<LoadingBar />
				{
					data && data.length > 0 &&
					<React.Fragment>
						{
							props.children
						}
						<ListHeader resultCount={resultCount} />
						<table className="table">
							<thead>
								<tr>
									<th>Titill</th><th width="40%">Heimildarmenn</th><th>Samsafn</th>
								</tr>
							</thead>
							<tbody>
								{
									data.map((item, index) => <tr key={index}>
										<td>
											<Link to={helpers.routerUrls['vidtol']+item.id}>
												{item.name}
											</Link>
										</td>
										<td>{helpers.metadataList(item.sources, helpers.routerUrls['einstaklingar'], 'id', 'name')}</td>
										<td>{
											item.collection && <Link to={helpers.routerUrls['vidtol']+'?safn='+item.collection.id}>{item.collection.name}</Link>
										}</td>
									</tr>)
								}
							</tbody>
						</table>
						{
							resultCount > config.pageSize &&
							<PaginationButtons resultCount={resultCount}
								previousUrl={previousUrl}
								nextUrl={nextUrl} />
						}
					</React.Fragment>
				}
				{
					data && data.length == 0 && !loading &&
					<NoResults />
				}
			</div>
		</React.Fragment>
	)
}

export default InterviewList;
